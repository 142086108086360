import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function DeExpertiseVanOnzeBackEndDeveloper(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> DE EXPERTISE VAN ONZE BACK-END DEVELOPER </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Onze back-end developers zijn ware meesters in hun vakgebied en bieden een breed scala aan
                        expertise.
                        Van databasebeheer en serverconfiguratie tot het implementeren van complexe algoritmes, u kunt
                        rekenen op de vaardigheden van <Link to={"/programmeur-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("onze_programmeurs");
                        }}>
                        onze programmeurs</a></Link>.
                        Als <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("uw_webdesignbureau");
                        }}>
                        uw webdesignbureau</a></Link> zorgen wij ervoor dat elke website precies is afgestemd op de behoeften
                        van uw bedrijf,
                        met backend-systemen die de efficiëntie en effectiviteit van uw online aanwezigheid
                        maximaliseren.
                        Of u nu een <Link to={"/app-maken-van-website"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("app_wilt_maken_van_uw_website");
                        }}>
                        app wilt maken van uw website</a></Link> of specifieke backend-functies nodig heeft, onze developers staan klaar
                        om uw project te verfijnen.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/b43a2395-d072-4ea8-a35d-161d75758c98/0_1.png"}
                    />
                </div>
            </div>
        </div>
    )
}