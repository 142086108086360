import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function ProfessioneelWebdesignAlsFundament(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> PROFESSIONEEL WEBDESIGN ALS FUNDAMENT </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Een professioneel webdesign is meer dan alleen visueel aantrekkelijk zijn;
                        het gaat om het creëren van een naadloze gebruikerservaring die bezoekers omzet in klanten.
                        Wanneer u besluit een website te laten bouwen door ons, zetten we uw wensen en de behoeften van uw klanten centraal.
                        Dit resulteert in een website die niet alleen mooi is, maar ook functioneel en responsief.
                        Bovendien bieden we de mogelijkheid om een <Link to={"/app-maken-van-website"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("app_te_maken_van_uw_website");
                        }}>
                        app te maken van uw website</a></Link>, waardoor uw bereik en
                        engagement met uw mobiele gebruikers aanzienlijk worden vergroot.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/d178e305-af11-41ea-b466-a6b80c5e86c5/0_0.png"}
                    />
                </div>
            </div>
        </div>
    )
}