import React from "react";

import LinkBanner from "../components/linkbanner";
import {GetRoutePage} from "../utils/routes";
import BreadCrumb from "../components/BreadCrumb";
import ImageBanner3 from "../components/webdesignbureau-aalst/imagebanner3";
import OnzePartners from "../components/OnzePartners";
import DeVoordelenVanEenAppMakenVanUwWebsite
    from "../components/app-maken-van-website/devoordelenvaneenappmakenvanuwwebsite";
import OntdekDeMogelijkhedenVoorUwBedrijf from "../components/app-maken-van-website/ontdekdemogelijkhedenvooruwbedrijf";
import Appmakenvanwebsite2 from "../components/app-maken-van-website/appmakenvanwebsite2";
import { Link } from "react-router-dom";

export default function AppMakenVanWebsite(props) {

    return (
        <div className={""}>
            <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden">
                <>
                    <div className="flex flex-row w-full h-full bg-base-100 overflow-y-auto overflow-x-hidden justify-center items-center">
                        <div className={"flex flex-col h-full w-full lg:w-2/3 xl:w-[1281px] p-8"}>
                            <div className="mt-8">
                            </div>

                            <LinkBanner/>

                            <BreadCrumb/>

                            <Appmakenvanwebsite2 />

                            <DeVoordelenVanEenAppMakenVanUwWebsite />

                            <ImageBanner3 />

                            <OntdekDeMogelijkhedenVoorUwBedrijf />

                            {/*<OnzePartners/>*/}

                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}