import React from "react";
import LinkBanner from "../components/linkbanner";
import BreadCrumb from "../components/BreadCrumb";
import {GetRoutePage} from "../utils/routes";
import ProgrammeurInAalst2 from "../components/programmeur-aalst/programmeurinaalst2";
import ImageBanner3 from "../components/webdesignbureau-aalst/imagebanner3";
import OnzePartners from "../components/OnzePartners";
import OnzeProgrammeursZijnDeSleutelTotEenFeilozeEnModerneWebsite
    from "../components/programmeur-aalst/onzeprogrammeurszijndesleuteltoteenfeilozeenmodernewebsite";
import NeemDeStapNaarDigitaleVoorsprong from "../components/programmeur-aalst/neemdestapnaardigitalevoorsprong";


export default function ProgrammeurAalst(props) {

    return (
        <div className={""}>
            <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden">
                <>
                    <div
                        className="flex flex-row w-full h-full bg-base-100 overflow-y-auto overflow-x-hidden justify-center items-center">
                        <div className={"flex flex-col h-full w-full lg:w-2/3  xl:w-[1281px] p-8"}>
                            <div className="mt-8">
                            </div>

                            <LinkBanner/>

                            <BreadCrumb />

                            <ProgrammeurInAalst2 />

                            <OnzeProgrammeursZijnDeSleutelTotEenFeilozeEnModerneWebsite />

                            <ImageBanner3 />

                            <NeemDeStapNaarDigitaleVoorsprong />

                            {/*<OnzePartners/>*/}

                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}