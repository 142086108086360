import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import {tabs} from "./dashboard";
import { Link } from "react-router-dom";

export default function TopMenu(props) {
    const {sidebarOpen, setSidebarOpen, openTab, setOpenTab} = props;
    return (
        <div className="flex flex-row w-full h-16 bg-base-100 justify-between items-center">
            <div className={"flex flex-row-reverse lg:flex-row w-full lg:w-1/6 h-full justify-between lg:justify-start items-center"}>
                <div className="tooltip lg:tooltip-right tooltip-left tooltip-primary" data-tip="Open sidebar">
                    <div className={"btn " + (sidebarOpen ? "btn-primary" : "btn-ghost")} onClick={() => { setSidebarOpen(!sidebarOpen); }}>
                        {sidebarOpen ? <MenuOpenIcon/> : <MenuIcon/>}
                    </div>
                </div>
                <div className={"flex lg:hidden flex-row w-full h-full justify-center items-center"}>
                    tmCMS AdminDash
                </div>
                <div className="tooltip tooltip-right tooltip-primary" data-tip="Leave administration panel">
                    <Link to={"/"} >

                        <div className={"btn btn-ghost"} onClick={() => {

                        }}>
                            <HomeIcon/>
                        </div>
                    </Link>
                </div>
                <div className={"hidden lg:flex flex-row w-full h-full justify-center items-center"}>
                    TMCMS AdminDash
                </div>
            </div>

            <div className="hidden lg:flex flex-row w-1/3 h-full bg-base-100 justify-center items-center uppercase">
                {tabs.map((tab, index) => (
                    <div key={tab.id} className={"btn btn-ghost " + (openTab === tab.id ? " text-primary" : " text-secondary")}
                         onClick={() => {
                             setOpenTab(tab.id);
                         }}>
                        {tab.display}
                    </div>
                ))}
            </div>
        </div>
    )
}