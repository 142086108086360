import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function VersterkUwOnlineAanwezigheid(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> VERSTERK UW ONLINE AANWEZIGHEID </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Bent u klaar om uw online aanwezigheid te versterken met de hulp van een deskundige back-end developer?
                        Als <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("uw_it-partner");
                        }}>
                        uw IT-partner</a></Link> zijn wij hier om u te begeleiden bij elke stap, van de initiële
                        planning tot de uiteindelijke uitvoering.
                        Ons team van back-end developers werkt nauw met u samen om ervoor te zorgen dat uw <Link to={"/website-op-maat"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("website_op_maat");}}>
                        website op maat</a></Link> niet alleen vandaag maar ook in de toekomst aan uw zakelijke behoeften
                        voldoet.
                        Neem vandaag nog contact met ons op en ontdek hoe we samen uw digitale ambities kunnen
                        realiseren.
                    </p>
                </div>
            </div>
        </div>
    )
}