import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function ZetNuDeStapNaarOnlineSucces(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> ZET NU DE STAP NAAR ONLINE SUCCES </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Heeft u de ambitie om online te groeien en uw concurrentie voor te blijven? Neem dan vandaag nog
                        contact op met ons webdesignbureau. Onze webdevelopers staan klaar om uw project te bespreken en
                        samen met u de mogelijkheden te verkennen. Als <Link to={"/"}><a
                            className="link-info link cursor-pointer text-primary font-semibold"
                            onClick={() => { ReportClick("betrouwbare_it_partner");}}>
                            betrouwbare IT-partner</a></Link> bieden wij u de
                        oplossingen die u nodig heeft. Met de grootste zorg behandelen we als websitebouwer elk aspect
                        van uw online aanwezigheid. Door innovatieve oplossingen en geavanceerde functionaliteiten te
                        integreren, speelt onze webontwikkelaar een cruciale rol in het realiseren van uw digitale
                        doelstellingen. Zet nu de stap naar online succes en laat ons samen uw digitale toekomst
                        vormgeven.
                    </p>
                </div>
            </div>
        </div>
    )
}