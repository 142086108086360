import VideoPlayer from "./VideoPlayer";
import {useAppContext} from "../context/appContext";
import React, {useState} from "react";
import {Button} from "react-daisyui";
import {ScrollToSection} from "../utils/general";
import {ReportClick} from "../utils/gtag";
import {Link} from "react-router-dom";
import GIF from "../assets/gif/techmaniagif.gif"
import digitaalmaatwerktitlelogo from "../assets/img/digitaalmaatwerk.png"
import DevicesIcon from "@mui/icons-material/Devices";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WebIcon from "@mui/icons-material/Web";
import TMLogo from "../assets/img/gertc_minimalistic_logo_TM.png"

export default function HeroLanding1(props) {
    const { openPage } = useAppContext();
    const [scrollPos, setScrollPos] = useState(0);


    return (
        <section className="flex h-full w-full overflow-hidden justify-center items-center content-center" id="home"
        style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "auto",
            width: "100%",
            flexWrap: "wrap",
            backgroundImage: `url(${GIF})`
        }}>
            <div className={"flex w-[100%] h-auto bg-secondary bg-opacity-65 items-center justify-center content-center"}
                 style={{minHeight: "100vh"}}>
                <div className={"flex-col w-[90%] lg:w-1/2 text-center items-center justify-center align-middle content-center"}>
                    <div className="flex items-center justify-center">
                        <img
                            alt="TMLogo"
                            id="tech-mania-logo"
                            className="align-middle w-[10%] opacity-65"
                            src={TMLogo}
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <img
                            alt="TMLogo"
                            id="tech-mania-logo"
                            className="align-middle"
                            src={digitaalmaatwerktitlelogo}
                        />
                    </div>

                    {/*<div className="container">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                                <Link to={"/diensten"}
                                      onClick={(e) => {
                                          ScrollToSection("opmaatgemaaktewebsites");
                                          ReportClick("diensten_opmaatgemaaktewebsites");
                                      }}>
                                    <div
                                        className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                                        <div className="px-4 py-5 leading-relaxed">
                                            <div
                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                                <DevicesIcon sx={{fontSize: 34}}/>
                                            </div>
                                            <h6 className="text-xl font-semibold text-secondary-content text-shadow-lg">WEBSITES</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                            <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                                <Link to={"/diensten"}
                                      onClick={(e) => {
                                          ScrollToSection("mobieleapplicatieontwikkeling");
                                          ReportClick("diensten_mobieleapplicatieontwikkeling");
                                      }}>
                                    <div
                                        className="relative flex flex-col min-w-0 bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                                        <div className="px-4 py-5 leading-relaxed">
                                            <div
                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                                <i className="fas fa-award"></i>
                                                <PhoneIphoneIcon sx={{fontSize: 34}}/>

                                            </div>
                                            <h6 className="text-xl font-semibold text-secondary-content text-shadow-lg">MOBILE
                                                APPS</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                                <Link to={"/diensten"}
                                      onClick={(e) => {
                                          ScrollToSection("webapplicatieontwikkeling");
                                          ReportClick("diensten_webapplicatieontwikkeling");
                                      }}>
                                    <div
                                        className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                                        <div className="px-4 py-5 leading-relaxed">
                                            <div
                                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                                <i className="fas fa-award"></i>
                                                <WebIcon sx={{fontSize: 34}}/>

                                            </div>
                                            <h6 className="text-xl font-semibold text-secondary-content text-shadow-lg">WEB
                                                APPS</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>*/}

                    <div
                        className={"flex rounded-xl items-center justify-center"}>
                        <div className={"flex items-center justify-center"}>
                            <p className="flex lg:w-5/6 h-auto py-8 font-semibold text-shadow-lg text-base-100 text-xl leading-snug lg:leading-relaxed text-center items-center justify-center content-center">
                                Op zoek naar een partner voor op maat gemaakte websites en mobiele applicaties?
                                Ons deskundig team staat klaar om uw digitale visie tot leven te brengen, of u
                                nu
                                een start-up bent of een gevestigd bedrijf.
                                Neem vandaag nog contact met ons op en ontdek hoe wij u kunnen helpen om te
                                groeien
                                en te gedijen in de digitale wereld.
                            </p>
                        </div>
                    </div>

                    <div className={"flex-col lg:flex-row items-center justify-center"}>
                        <div className="py-4 inline-flex">
                            <Button color="primary" onClick={(e) => {
                                ScrollToSection("herolandingsection1");
                                ReportClick("meer_weten_landing");
                            }}>MEER WETEN</Button>
                        </div>
                        <div className="py-4 mb-10 flex text-shadow-lg text-base-100 items-center justify-center">
                            <Link to={"/contact"}>
                                <Button color="primary" onClick={() => {
                                    ReportClick("contacteer_ons_landing");
                                }}>CONTACTEER ONS</Button>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};
