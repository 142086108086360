import React, { useState, useEffect } from 'react';
import {Dropdown, Menu} from "react-daisyui";
import {Airplay, CookieIcon, Moon, Sun} from "lucide-react";
import secureLocalStorage from "react-secure-storage";
import {ReportClick} from "../utils/gtag";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ContrastRoundedIcon from "@mui/icons-material/ContrastRounded";
import {useAppContext} from "../context/appContext";

export const themes = ["Licht", "Donker"];

export default function SettingsMenu(props){
    const {setActiveTheme, activeTheme, ToggleTheme} = useAppContext();
    const [showSettings, setShowSettings] = useState(false);


    return (
        <Dropdown className="dropdown-end dropdown-bottom">
            <Dropdown.Toggle className={""}
                             size={"sm"}
                             shape={"square"}
                             color={"ghost"}
                             onClick={() => {
                                 ReportClick("Instellingen");
                             }}>
                <p className="hidden lg:flex"><SettingsRoundedIcon/></p>
                <p className="flex lg:hidden w-full h-auto flex-row justify-center items-center">Instellingen</p>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-auto bg-base-200 bg-opacity-90">
                <Menu size={"xs"}>
                    <Menu.Item>
                        <div className={"w-auto h-auto flex flex-row gap-3 text-sm tooltip"}
                             data-tip="Pas het Thema aan"
                             onClick={() => {
                                 ReportClick("Thema");
                                 setShowSettings(!showSettings);
                             }}>
                            <ContrastRoundedIcon sx={{fontSize: 16}}/>Thema
                        </div>
                    </Menu.Item>

                    {showSettings && themes && themes.map((el, ind) => (
                        <Menu.Item key={el} onClick={() => ToggleTheme(el)}>
                            <div className={"flex gap-3 text-sm bg-opacity-90 " + (activeTheme === el ? "bg-base-300" : "bg-base-200")}>
                                {ind === 0 ? <Sun className="h-5" /> : <Moon className="h-5" /> }
                                {el}
                            </div>
                        </Menu.Item>
                    ))}

                    <Menu.Item>
                        <div className={"w-auto h-auto flex flex-row gap-3 text-sm tooltip"}
                             data-tip="Pas je Cookie voorkeuren aan" onClick={() => {
                            ReportClick("Privacy Instellingen");
                            window.Sddan.cmp.displayUI();
                        }}>
                            <CookieIcon className={"flex h-4 w-4"}/>
                            <div className={"flex flex-row flex-wrap justify-start items-center text-wrap h-auto w-full"}>Privacy Instellingen</div>
                        </div>
                    </Menu.Item>
                </Menu>
            </Dropdown.Menu>
        </Dropdown>
    )
}