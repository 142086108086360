import {useEffect, useState} from "react";
import {TicketPage} from "./tickets";
import {AccountsPage} from "./accounts";
import SideBar from "./sidebar";
import TopMenu from "./topmenu";
import {AccountBlock, SessionsBlock, TicketBlock} from "./widgets";
import StoreContainer from "./estore/estore";

export const tabs = [{id:"dash", display: "Dashboard"},{id:"store", display: "EStore"} ,{id:"tickets", display: "Tickets"},{id:"accounts", display: "Accounts"}];

export default function AdminDashboard(props) {
    const {session} = props;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openTab, setOpenTab] = useState("dash");
    const [tickets, setTickets] = useState([]);
    const [users, setUsers] = useState([]);
    const [loadingTickets, setLoadingTickets] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);

    useEffect(() => {
        GetUsers();
        GetTickets();

    }, [])

    function GetUsers() {
        setLoadingUsers(true);

    }

    function GetTickets() {
        setLoadingTickets(true);

    }

    if (session === null || !session.admin) {
        return (
            <div>
                No permission to view.
            </div>
        )
    } else return (
        <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden font-admin">
            <TopMenu openTab={openTab} setOpenTab={setOpenTab} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
            <div className="flex flex-col lg:flex-row w-full h-full bg-base-200 border-base-200 border overflow-hidden">
                {sidebarOpen &&
                    <SideBar users={users} openTab={openTab} setOpenTab={setOpenTab} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>}
                <div className={"flex flex-col h-full bg-base-200 overflow-hidden "  + (sidebarOpen ? "w-full lg:w-5/6" : "w-full")}>
                    {openTab === "dash" && <DashContainer loadingTickets={loadingTickets} loadingUsers={loadingUsers} users={users} tickets={tickets} setOpenTab={setOpenTab} />}
                    {openTab === "tickets" && <TicketPage loadingTickets={loadingTickets} GetTickets={GetTickets} tickets={tickets}/>}
                    {openTab === "accounts" && <AccountsPage loadingUsers={loadingUsers} users={users} />}
                    {openTab === "store" && <StoreContainer />}
                </div>
            </div>
            <div className="flex flex-row w-full h-12 bg-base-100 justify-between items-center">
                <div className={"flex flex-col h-full w-10"}>

                </div>
                <div className={"flex flex-col h-full w-80 justify-center items-center text-xs lg:text-sm"}>
                    <p>Copyright © Tech-Mania CMS 2024</p>
                </div>
            </div>
        </div>
    )
}

function DashContainer(props) {
    const {sidebarOpen, token, setOpenTab, users, tickets, loadingTickets, loadingUsers} = props;
    return (
            <div className={"flex flex-col h-auto w-full"}>
                <div className={"flex flex-wrap w-full items-center justify-center h-auto gap-4 p-2 lg:p-10"}>
                    <AccountBlock loadingUsers={loadingUsers} setOpenTab={setOpenTab} users={users} token={token}/>
                    <SessionsBlock token={token} users={users}/>
                    <TicketBlock loadingTickets={loadingTickets} tickets={tickets} setOpenTab={setOpenTab} users={users} token={token}/>
                </div>
            </div>
    )
}