import React, {useEffect, useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import logo from "../assets/img/gertc_minimalistic_logo_TM.png"
import {ScrollToSection} from "../utils/general";
import {ReportClick} from "../utils/gtag";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

/* Logo scaled niet goed en scale verhoudingen ook niet */

export default function FooterV1 (props) {
    const [toggled, setToggled] = useState(localStorage.getItem("footerOpen") ? localStorage.getItem("footerOpen") : "false");
    const [retoggle, setRetoggle] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);

    function Toggle(stat){
        if(stat) setToggled("true");
        else setToggled("false");
        setRetoggle(true);
    }

    useEffect(() => {
        localStorage.setItem("footerOpen",toggled);
       if(retoggle && toggled === "true") ScrollToSection("end");
    }, [retoggle, toggled]);

    return (
        <div>
            <footer id={"footer"} className="flex flex-col bg-neutral text-neutral-content" data-theme="dark" onPointerEnter={() => {setMouseOver(true);}} onPointerLeave={() => {setMouseOver(false);}}>
                    {toggled === "true" &&
                        <div className="grid lg:grid-cols-2 ">
                            <div className="flex flex-row items-center justify-center lg:justify-around gap-20 md-12 border-e border-t border-white/20 p-8 sm:p-10 md:px-16 xl:px-40 xl:py-16">
                                <div className="flex items-start justify-center">
                                    <img
                                            alt="TMHeroSection2"
                                            id="hero-image-hero-section-2"
                                            className="w-1/2 h-1/2 lg:w-1/3 lg:h-1/3 align-middle rounded-lg hover:scale-105"
                                            src={logo}
                                    />
                                </div>
                                <nav>
                                        <div className="grid grid-flow-col gap-4 md:gap-5 lg:gap-10">
                                            <a>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     viewBox="0 0 24 24" className="cursor-pointer fill-current">
                                                    <path
                                                        d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                                                </svg>
                                            </a>
                                            <a>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     viewBox="0 0 24 24" className="cursor-pointer fill-current">
                                                    <path
                                                        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                                                </svg>
                                            </a>
                                            <a>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                                     viewBox="0 0 24 24" className="cursor-pointer fill-current">
                                                    <path
                                                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </nav>
                            </div>
                            <div className="md-12 border-t border-white/20 p-8 sm:p-10 md:px-16 xl:px-40 xl:py-16">
                                        <div className="grid grid-cols-3 gap-6 md:grid-cols-3 justify-items-center">
                                            <div className="flex flex-col gap-5">
                                                <h2 className="text-xl font-medium">Tech Mania</h2>
                                                <div className="space-y-2">
                                                    <div>
                                                        <Link to={"/"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => { ReportClick("footer_home"); }}>
                                                                Home
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/info"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => {ReportClick("ons_team");}}>
                                                                Ons Team
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_blog")}>
                                                                Blog
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_faq")}>
                                                                FAQ
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => { ReportClick("footer_contact");}}>
                                                                Contact
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-5">
                                                <h2 className="text-xl font-medium">Snelle Links</h2>
                                                <div className="space-y-2">
                                                    <div>
                                                        <Link to={"/diensten"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => {
                                                                    ReportClick("footer_onze_diensten");
                                                                }}>
                                                                Onze diensten
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_prijzen")}>
                                                                Prijzen
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_producten")}>
                                                                Producten
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-5">
                                                <h2 className="text-xl font-medium">Contact</h2>
                                                <div className="space-y-2">
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => { ReportClick("footer_contacteer_ons");}}>
                                                                Contacteer ons
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_ondersteuning")}>
                                                                Ondersteuning
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_nieuwsbrief")}>
                                                                Nieuwsbrief
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_voorwaarden")}>
                                                                Voorwaarden
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={"/contact"} >
                                                            <div className="text-base cursor-pointer transition-all duration-500 hover:text-primary"
                                                                onClick={() => ReportClick("footer_privacybeleid")}>
                                                                Privacybeleid
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>}
                    <div className="flex flex-row overflow-hidden h-14 items-center justify-between border-t border-white/20 px-8 text-center lg:px-40 py-2 text-sm" id={"end"}>
                        <div className={"flex w-10 h-10"}></div>
                        <div className={"flex w-auto flex-row justify-center text-xs lg:text-sm items-center min-h-10 overflow-hidden hover:text-primary"}>
                            <Link to={"/"} >
                                <div className={"cursor-pointer"} onClick={() => {
                                    ReportClick("footer_home");
                                }}>
                                    Copyright © 2023 - {new Date().getFullYear()}{" "}Tech Mania{" "}
                                </div>
                            </Link>
                        </div>
                            <div className={"flex flex-row h-full w-10 justify-center items-center" }>
                                {mouseOver && <div className={"flex flex-row cursor-pointer justify-center text-white items-center rounded-lg h-8 w-8 bg-primary hover:bg-opacity-80"} onClick={() => {
                                    ReportClick("footer_expanded-collapsed");
                                    Toggle(toggled !== "true");
                                }}>
                                    {toggled === "true" ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
                                </div>}
                            </div>
                    </div>
            </footer>
        </div>
    );
};
