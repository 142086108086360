import React from "react";
import {GetRoutePage} from "../utils/routes";
import {useAppContext} from "../context/appContext";
import {useLocation} from "react-router-dom";
import { Link } from "react-router-dom";

export default function BreadCrumb(props) {
    const {openPage} = useAppContext();
    let route = useLocation();

    return (
        <div className={"pt-2 lg:pt-4"}>
            <div
                className={"flex flex-row w-full lg:w-3/5 xl:w-full justify-items-center xl:justify-start items-center h-10 gap-2 font-semibold"}>
                <Link to={"/"} >
                <div className={"hover:text-primary cursor-pointer"} onClick={() => {

                }}>Home
                </div>
                </Link>
                |
                <p className={"text-primary cursor-pointer"} onClick={() => {
                }}>{route.hash}</p>
            </div>
        </div>
    )
}