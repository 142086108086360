import React from "react";
import LinkBanner from "../components/linkbanner";
import {GetRoutePage} from "../utils/routes";
import BreadCrumb from "../components/BreadCrumb";
import ProgrammeurInAalst from "../components/webdesignbureau-aalst/programmeurinaalst";
import MaatwerkVanUwWebdesignbureau from "../components/webdesignbureau-aalst/maatwerkvanuwwebdesignbureau";
import InnovatieEnTechnologieVoorop from "../components/webdesignbureau-aalst/innovatieentechnologievoorop";
import ImageBanner3 from "../components/webdesignbureau-aalst/imagebanner3";
import ZetNuDeStapNaarOnlineSucces from "../components/webdesignbureau-aalst/zetnudestapnaaronlinesucces";
import OnzePartners from "../components/OnzePartners";
import DeExpertiseVanOnzeBackEndDeveloper
    from "../components/back-end-developer-aalst/deexpertisevanonzebackenddeveloper";
import VersterkUwOnlineAanwezigheid from "../components/back-end-developer-aalst/versterkuwonlineaanwezigheid";
import BackEndDeveloperAalst2 from "../components/back-end-developer-aalst/backenddeveloperinaalst2";


export default function BackEndDeveloperAalst(props) {

    return (
        <div className={""}>
            <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden">
                <>
                    <div
                        className="flex flex-row w-full h-full bg-base-100 overflow-y-auto overflow-x-hidden justify-center items-center">
                        <div className={"flex flex-col h-full w-full lg:w-2/3 xl:w-[1281px] p-8"}>
                            <div className="mt-8">
                            </div>

                            <LinkBanner/>

                            <BreadCrumb/>

                            <BackEndDeveloperAalst2 />

                            <DeExpertiseVanOnzeBackEndDeveloper />

                            <ImageBanner3 />

                            <VersterkUwOnlineAanwezigheid />

                            {/*<OnzePartners/>*/}

                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}