import feature1Image from "../assets/img/e-commercesolutions.png";
import feature2Image from "../assets/img/onderhoudservices.png";
import feature3Image from "../assets/img/UIandUXServices.png";
import feature4Image from "../assets/img/AnalyseEnRapportage.png"
import {Badge, Button} from "react-daisyui";
import {useAppContext} from "../context/appContext";

export const Feature2 = (props) => {
    const {openPage} = useAppContext();
    return (
        <section className="relative py-8 lg:py-20" id="features">
            {/*<div className="absolute start-[10%] z-0">
                <div className="pointer-events-none aspect-square w-60 rounded-full bg-gradient-to-r from-primary/10 via-violet-500/10 to-purple-500/10 blur-3xl [transform:translate3d(0,0,0)] lg:w-[600px]"></div>
            </div>*/}

            <div className="flex w-[100%] justify-center">

                <div className="flex flex-wrap w-4/5 gap-12 z-[2] py-8 lg:py-10 justify-center">

                    <div className="flex flex-col lg:w-1/3 p-8 border-primary rounded-lg border-2 hover:scale-105 text-center shadow-md transition-all hover:shadow-xl justify-between">

                        <div className="">
                            <Badge color={"primary"}>E-commerce</Badge>

                            <h3 className="mt-4 text-3xl font-bold">E-COMMERCE-OPLOSSINGEN</h3>
                            <p className="mt-2 text-base font-medium">
                                Bij Tech Mania bieden we je krachtige e-commerceoplossingen aan die je online winkel naar
                                nieuwe
                                hoogten tillen.
                                Van het opzetten van je winkel tot het beheren van betalingsgateways en voorraadbeheer,
                                leveren wij op maat gemaakte oplossingen voor een naadloze winkelervaring voor jouw klanten.
                            </p>

                            <ul className="mt-4 list-inside list-disc text-base">
                                <li>Online winkel</li>
                                <li>Winkelwagentjes</li>
                                <li>Betalingsgateways</li>
                                <li>Voorraadbeheer</li>
                                <li>Naadloze ervaring</li>
                                <li>Geoptimaliseerde winkelervaring</li>
                            </ul>


                        </div>

                        <div>
                            <div className="p-8 order-first lg:order-last">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt="e-commerceoplossingen"
                                        className="overflow-hidden rounded-ss-lg"
                                        src={feature1Image}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col lg:w-1/3 p-8 border-primary rounded-lg border-2 hover:scale-105 text-center shadow-md transition-all hover:shadow-xl justify-between">
                        <div className="">
                            <Badge color="primary">Onderhoud</Badge>
                            <h3 className="mt-4 text-3xl font-bold">
                                ONDERHOUD EN ONDERSTEUNING
                            </h3>
                            <p className="mt-2 text-base font-medium">
                            Bij Tech Mania ontdek je onze uitgebreide onderhouds- en ondersteuningsdiensten,
                                ontworpen om ervoor te zorgen dat jouw digitale platformen soepel blijven werken en
                                up-to-date blijven met de laatste technologische ontwikkelingen.
                                Wij bieden continue ondersteuning om jou gemoedsrust te geven terwijl je je richt op het
                                laten groeien van jouw bedrijf.
                            </p>

                            <ul className="mt-4 list-inside list-disc text-base">
                                <li>Continue ondersteuning</li>
                                <li>Technologische ontwikkelingen</li>
                                <li>Up-to-date houden</li>
                                <li>Onderhoudsdiensten</li>
                                <li>Ondersteuningsservices</li>
                                <li>Gemoedsrust</li>
                            </ul>

                            <div className="p-8 order-first lg:order-last mt-4">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt="onderhoudenondersteuning"
                                        className="overflow-hidden rounded-ss-lg"
                                        src={feature2Image}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-col lg:w-1/3 p-8 border-primary rounded-lg border-2 hover:scale-105 text-center shadow-md transition-all hover:shadow-xl justify-between">
                        <div className="">
                            <Badge color={"primary"}>User Interface</Badge>
                            <h3 className="mt-4 text-3xl font-bold">UI/UX DESIGN SERVICES</h3>
                            <p className="mt-2 text-base font-medium">
                                Bij Tech Mania geloven we in de kracht van een intuïtieve en aantrekkelijke
                                gebruikerservaring
                                om uw digitale platformen te onderscheiden van de concurrentie.
                                Onze UI/UX Design Services zijn gericht op het creëren van gebruiksvriendelijke interfaces
                                die niet alleen de conversie verhogen, maar ook de klanttevredenheid verbeteren.
                                Laat ons deskundige team van ontwerpers u helpen bij het ontwikkelen van een interface die
                                de essentie
                                van uw merk weerspiegelt en uw doelgroep aanspreekt.
                            </p>

                            <ul className="mt-4 list-inside list-disc text-base">
                                <li>Intuïtieve gebruikerservaring</li>
                                <li>Aantrekkelijke interface</li>
                                <li>Gebruiksvriendelijkheid</li>
                                <li>Conversieverhoging</li>
                                <li>Klanttevredenheid</li>
                            </ul>

                            <div className="p-8 order-first lg:order-last mt-4">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt="webapplicatieontwikkeling"
                                        className="overflow-hidden rounded-ss-lg"
                                        src={feature3Image}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col lg:w-1/3 p-8 border-primary rounded-lg border-2 hover:scale-105 text-center shadow-md transition-all hover:shadow-xl justify-between">
                        <div className="">
                            <Badge color="primary">Analytics</Badge>
                            <h3 className="mt-4 text-3xl font-bold">
                                ANALYSE EN RAPPORTAGE
                            </h3>
                            <p className="mt-2 text-base font-medium">
                                Bij Tech Mania geloven we in de kracht van data om uw digitale strategie te optimaliseren en
                                resultaten te behalen.
                                Onze Analyse en Rapportagediensten zijn gericht op het meten en analyseren van
                                prestatiegegevens,
                                het identificeren van kansen voor verbetering en het leveren van gedetailleerde rapporten om
                                u te
                                helpen bij het nemen van datagestuurde beslissingen.
                                Laat ons team van data-analisten u helpen bij het ontwikkelen van een strategie die
                                uw bedrijf naar nieuwe hoogten tilt en uw concurrenten voor blijft.
                            </p>

                            <ul className="mt-4 list-inside list-disc text-base">
                                <li>Datagestuurde beslissingen</li>
                                <li>Prestatiegegevens analyseren</li>
                                <li>Kansen voor verbetering identificeren</li>
                                <li>Gedetailleerde rapporten</li>
                                <li>Blijf de concurrentie voor</li>
                            </ul>

                            <div className="p-8 order-first lg:order-last mt-4">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt="gameseneducatievesoftware"
                                        className="overflow-hidden rounded-ss-lg"
                                        src={feature4Image}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};
