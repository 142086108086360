import { Button } from "react-daisyui";
import heroImage from "../assets/img/React.png";
import React from "react";
import {ReportClick} from "../utils/gtag";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

export default function HeroReact(props) {
    const containerRef = React.useRef(null);

    return (
        <section className="container py-8 lg:py-20 lg:mt-10" id="home">
            <div className="">
                <div className="grid gap-5 lg:gap-40 xl:grid-cols-2 items-center justify-center">
                    <div>
                        <h1 className="text-2xl font-bold lg:text-3xl">
                            REACT: DE KRACHT VAN MODERNE WEBONTWIKKELING BIJ TECH MANIA
                        </h1>
                        <p className=" mt-8 text-lg">
                            Bij Tech Mania omarmen we de nieuwste technologieën om onze klanten
                            vooruitstrevende digitale oplossingen te bieden.
                            Een van deze technologieën is React,
                            een JavaScript-bibliotheek die wordt gebruikt voor het bouwen van
                            dynamische gebruikersinterfaces, vooral voor single-page applicaties.
                            React maakt gebruik van herbruikbare componenten en een Virtual DOM-benadering,
                            waardoor ontwikkelaars efficiënter en gestructureerder kunnen werken.
                            Met een bloeiende community en een rijk ecosysteem aan tools en bibliotheken,
                            stelt React ons in staat om snel hoogwaardige en schaalbare projecten te
                            leveren die voldoen aan de eisen van onze klanten.
                            Als een van de nieuwste technologieën op het gebied van webontwikkeling,
                            blijft React een essentieel onderdeel van onze toolbox voor <Link to={"/diensten"} className="link-info link text-primary font-semibold" onClick={() => {
                            ReportClick("het_creëren_van_innovatieve_digitale_ervaringen");
                            }}> het creëren van
                            innovatieve digitale ervaringen. </Link>
                        </p>
                        <div className="mt-8 inline-flex">
                            <Button color="ghost" href={"https://react.dev/"} onClick={() => {ReportClick("meer_weten_over_react");}}>Meer weten over React</Button>
                        </div>
                    </div>
                    <div
                        className="flex items-center justify-center mt-10 lg:mt-0"
                    >
                        <img
                            alt="TM"
                            id="hero-image"
                            className="hover:scale-105"
                            src={heroImage}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
