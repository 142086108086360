import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function InnovatieEnTechnologieVoorop(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[514px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> INNOVATIE EN TECHNOLOGIE VOOROP </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Bij ons webdesignbureau ligt de focus niet alleen op het creëren van esthetisch aantrekkelijke
                        websites, maar ook op innovatie en het implementeren van de nieuwste technologieën. <Link to={"programmeur-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("onze_programmeurs");}}>
                        Onze programmeurs</a></Link> werken met geavanceerde
                        programmeertalen en frameworks om de meest betrouwbare en
                        efficiënte oplossingen te bieden. Daarnaast leggen we een sterke focus op de <Link to={"/mobiele-app-laten-maken"}><a
                            className="link-info link cursor-pointer text-primary font-semibold"
                            onClick={() => { ReportClick("ontwikkeling_van_mobiele_apps");}}>
                            ontwikkeling van mobiele apps</a></Link>, waarbij we de functionaliteit
                        en gebruikerservaring van uw mobiele platformen
                        optimaliseren. Onze inzet als websitebouwer garandeert dat de technische realisatie van uw
                        website met de hoogste precisie en aandacht voor detail wordt voltooid. Als webdeveloper zetten
                        we de nieuwste technologieën in om uw online projecten te realiseren. Door vooruit te denken en
                        continu te innoveren, positioneren wij u als marktleider in uw branche. Als webontwikkelaar
                        zorgen we ervoor dat uw digitale platformen naadloos integreren met de nieuwste trends en
                        technologieën.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-auto bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:h-[514px] xl:top-[1294px] left-[1206px] rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/5e4146ae-189e-45cd-829a-61d86257a72a/0_0.png"}
                    />
                </div>
            </div>
        </div>
    )
}