import feature1Image from "../assets/img/OpMaatGemaakteWebsites.png";
import feature2Image from "../assets/img/MobieleApps.png";
import feature3Image from "../assets/img/webapplicaties.png";
import feature4Image from "../assets/img/gameseneducatievesoftware.png";
import {Badge, Button} from "react-daisyui";
import {ReportClick} from "../utils/gtag";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

export const Feature = (props) => {
    const {openPage} = useAppContext();
    const [visible, setVisble] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        let mc = document.querySelector("#maincontainer")
        if(mc!== null) mc.addEventListener("scroll", (e) => {
            setScrollPos(e.target.scrollTop);
        });
        return(() => {
            mc.removeEventListener("scroll", (e) => {
                setScrollPos(e.target.scrollTop);
            });
        })
    })

    useEffect(() => {
        if(!visible) {
            let target = document.querySelector("#opmaatgemaaktewebsites");
            if(target !== null) {
                if(scrollPos > (target.scrollHeight +50)) setVisble(true);
            }
        }
    }, [scrollPos]);

    return (
        <section className="" id="features">
            <div className="absolute start-[10%] z-0">
                <div
                    className="pointer-events-none aspect-square w-60 rounded-full bg-gradient-to-r from-primary/10 via-violet-500/10 to-purple-500/10 blur-3xl [transform:translate3d(0,0,0)] lg:w-[600px]"></div>
            </div>
            <div className={""}>
                {/*<div className="absolute start-[10%] z-0">
                    <div
                        className="pointer-events-none aspect-square w-60 rounded-full bg-gradient-to-r from-primary/10 via-violet-500/10 to-purple-500/10 blur-3xl [transform:translate3d(0,0,0)] lg:w-[600px]"></div>
                </div>*/}
                <div className="relative z-[2] py-8 lg:py-20">
                    <div className="container flex flex-col items-center py-8">
                        <h2 className="inline text-4xl font-bold">MAXIMALISEER UW DIGITALE IMPACT</h2>

                        <p className="mt-4 text-lg sm:text-center">
                            Duik in Tech Mania's Uitgebreide Suite van Digitale Oplossingen.
                        </p>
                    </div>

                    <section id="opmaatgemaaktewebsites">
                        <div className="container relative z-[2] py-8 grid gap-8 lg:mt-20 xl:grid-cols-2 lg:gap-28">
                            <div className="order-last xl:order-first">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt=""
                                        className="overflow-hidden rounded-ss-lg scale 80"
                                        src={feature1Image}
                                    />
                                </div>
                            </div>

                            <div className="order-first mt-7 lg:mt-0">
                                <Badge color="primary">Websites</Badge>
                                <h3 className="mt-10 text-3xl font-bold">
                                    OP MAAT GEMAAKTE WEBSITES
                                </h3>
                                <p className="mt-2 text-base font-medium">
                                    Bij Tech Mania geloven we sterk in de kracht van een unieke online aanwezigheid die
                                    naadloos
                                    aansluit bij uw merkidentiteit en doelstellingen.
                                    Of het nu gaat om een eenvoudige informatieve website, een dynamische portfolio of
                                    een
                                    geavanceerd e-commerceplatform,
                                    wij leveren maatwerkwebsites die niet alleen de aandacht trekken, maar ook de
                                    betrokkenheid
                                    van uw bezoekers vergroten en conversies stimuleren.
                                    Met een focus op gebruiksvriendelijkheid, esthetiek en functionaliteit zorgen wij
                                    voor
                                    een
                                    op maat gemaakte webervaring die uw merk versterkt en
                                    uw bedrijf naar nieuwe hoogten tilt.
                                </p>

                                <ul className="mt-4 list-inside list-disc text-base">
                                    <li>Unieke online aanwezigheid</li>
                                    <li>Maatwerkwebsites</li>
                                    <li>Merkidentiteit</li>
                                    <li>Betrokkenheid bezoekers</li>
                                    <li>Conversiestimulatie</li>
                                    <li>Gebruiksvriendelijk</li>
                                </ul>

                                <div className="mt-16 inline-flex gap-3">
                                    <Link to={"/contact"}>
                                        <Button color="primary" onClick={(e) => {
                                            ReportClick("offerte_aanvragen_op_maat_gemaakte_websites");
                                        }}>Offerte Aanvragen</Button>
                                    </Link>
                                    {/*<Button color="primary">Meer Weten</Button>*/}
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr className="flex lg:hidden my-1 border-primary"/>

                    <section id="mobieleapplicatieontwikkeling">
                        <div className="container relative z-[2] py-8 grid gap-8 lg:mt-20 xl:grid-cols-2 lg:gap-28">

                            <div className="order-first mt-7 lg:mt-0">
                                <Badge color={"primary"}>Mobiele Apps</Badge>
                                <h3 className="mt-10 text-3xl font-bold text-wrap">
                                    MOBIELE APPLICATIE-ONTWIKKELING</h3>
                                <p className="mt-2 text-base font-medium">
                                    Bij Tech Mania focussen we op het leveren van hoogwaardige mobiele apps die uw
                                    bedrijf
                                    naar
                                    nieuwe hoogten tillen. Onze op maat gemaakte mobiele apps zijn ontworpen om een
                                    naadloze
                                    gebruikerservaring te
                                    bieden en waarde toe te voegen aan uw bedrijf, of het nu voor iOS of Android is. Met
                                    een
                                    focus op innovatie en kwaliteit,
                                    streven we ernaar om geavanceerde mobiele applicaties te leveren die uw
                                    bedrijfsdoelen
                                    ondersteunen en de verwachtingen van uw gebruikers overtreffen.
                                </p>

                                <ul className="mt-4 list-inside list-disc text-base text-wrap">
                                    <li>Innovatieve mobiele applicaties</li>
                                    <li>Op maat gemaakt</li>
                                    <li>Naadloze gebruikerservaring</li>
                                    <li>Bedrijfswaarde toevoegen</li>
                                    <li>Voor iOS en Android</li>
                                    <li>Uniek en op maat</li>
                                </ul>

                                <div className="mt-16 inline-flex">
                                    <Link to={"/contact"}>
                                        <Button color="primary" onClick={(e) => {
                                            ReportClick("offerte_aanvragen_mobiele_applicatieontwikkeling");
                                        }}>Offerte Aanvragen</Button>
                                    </Link>
                                    {/*<Button color="primary">Meer Weten</Button>*/}
                                </div>
                            </div>

                            <div className="order-last">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt=""
                                        className="overflow-hidden rounded-ss-lg scale 80"
                                        src={feature2Image}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr className="flex lg:hidden my-1 border-primary"/>

                    <section id="webapplicatieontwikkeling">
                        <div className="container relative z-[2] py-8 grid gap-8 lg:mt-20 xl:grid-cols-2 lg:gap-28">
                            <div className="order-last xl:order-first">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt=""
                                        className="overflow-hidden rounded-ss-lg scale 80"
                                        src={feature3Image}
                                    />
                                </div>
                            </div>

                            <div className="order-first mt-7 lg:mt-0">
                                <Badge color="primary">Web Apps</Badge>
                                <h3 className="mt-10 text-3xl font-bold">
                                    WEB APPLICATIE-ONTWIKKELING
                                </h3>
                                <p className="mt-2 text-base font-medium">
                                    Bij Tech Mania voorzien we moderne bedrijven van efficiënte en krachtige
                                    webapplicaties
                                    die
                                    zijn afgestemd op hun specifieke behoeften.
                                    Onze webapplicaties zijn ontworpen om interne processen te automatiseren en
                                    klantgerichte
                                    oplossingen te bieden
                                    die uw bedrijfsprestaties optimaliseren en uw concurrentiepositie versterken.
                                </p>

                                <ul className="mt-4 list-inside list-disc text-base">
                                    <li>Efficiënte webapplicaties</li>
                                    <li>Optimalisatie bedrijfsprocessen</li>
                                    <li>Klantgerichte oplossingen</li>
                                    <li>Maatwerk ontwikkeling</li>
                                    <li>Verbeterde productiviteit</li>
                                    <li>Competitief voordeel</li>
                                </ul>

                                <div className="mt-16 inline-flex gap-3">
                                    <Link to={"/contact"}>
                                        <Button color="primary" onClick={(e) => {
                                            ReportClick("offerte_aanvragen_web_applicatieontwikkeling");
                                        }}>Offerte Aanvragen</Button>
                                    </Link>
                                    {/*<Button color="primary">Meer Weten</Button>*/}
                                </div>

                            </div>
                        </div>
                    </section>

                    <hr className="flex lg:hidden my-1 border-primary"/>

                    <section id="gameseneducatievesoftware">
                        <div className="container relative z-[2] py-8 grid gap-8 lg:mt-20 xl:grid-cols-2 lg:gap-28">
                            <div>
                                <Badge color={"primary"}>Games</Badge>
                                <h3 className="mt-10 text-3xl font-bold">GAMES EN EDUCATIEVE SOFTWARE</h3>
                                <p className="mt-2 text-base font-medium">
                                    Bij Tech Mania bieden we op maat gemaakte oplossingen voor games en educatieve
                                    software,
                                    waarbij we het belang van interactieve en boeiende ervaringen begrijpen, zowel voor
                                    entertainment als educatie.
                                    Onze expertise strekt zich uit tot het ontwikkelen van boeiende games voor diverse
                                    platforms
                                    en educatieve software die is afgestemd op verschillende leerdoelen,
                                    met een focus op het gebruik van technologie om het leerproces te verbeteren. Met
                                    Tech
                                    Mania
                                    ben je verzekerd van hoogwaardige oplossingen,
                                    waarbij we streven naar innovatie, kwaliteit en een unieke gebruikerservaring.
                                </p>

                                <ul className="mt-4 list-inside list-disc text-base">
                                    <li>Interactief</li>
                                    <li>Boeiend</li>
                                    <li>Divers</li>
                                    <li>Educatief</li>
                                    <li>Innovatief</li>
                                    <li>Kwaliteit</li>
                                </ul>

                                <div className="mt-16 inline-flex gap-3">
                                    <Link to={"/contact"}>
                                        <Button color="primary" onClick={(e) => {
                                            ReportClick("offerte_aanvragen_games_en_educatieve_software");
                                        }}>Offerte Aanvragen</Button>
                                    </Link>
                                    {/*<Button color="primary">Meer Weten</Button>*/}
                                </div>

                            </div>

                            <div className="order-last">
                                <div
                                    className="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl">
                                    <img
                                        alt=""
                                        className="overflow-hidden rounded-ss-lg scale 80"
                                        src={feature4Image}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <hr className="flex lg:hidden my-1 border-primary"/>

                </div>
            </div>

        </section>
    );
};
