import React from "react";
import {useAppContext} from "../context/appContext";

export default function ImageBanner1(props) {

    return (
        <section id="image-banner-1">
            <div className="flex w-full h-auto bg-gradient-to-r from-cyan-500 to-blue-500">
                <div className="container py-8 lg:py-14">
                    <div className="flex items-center justify-center">
                        <div className="flex flex-wrap">

                            <div className="lg:pt-6 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                                    <img
                                        alt="..."
                                        src="https://cdn.midjourney.com/d178e305-af11-41ea-b466-a6b80c5e86c5/0_0.png"
                                        className="w-full align-middle rounded-lg hover:scale-105"
                                    />
                                </div>
                            </div>

                            <div className="lg:pt-6 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                                    <img
                                        alt="..."
                                        src="https://cdn.midjourney.com/ae9169d6-5080-480e-aac2-cb017ee49080/0_1.png"
                                        className="w-full align-middle rounded-lg hover:scale-105"
                                    />
                                </div>
                            </div>

                            <div className="lg:pt-6 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                                    <img
                                        alt="..."
                                        src="https://cdn.midjourney.com/c4f5a4d4-8671-43bd-8386-bdb4bafb3713/0_0.png"
                                        className="w-full align-middle rounded-lg hover:scale-105"
                                    />
                                </div>
                            </div>

                            <div className="lg:pt-6 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                                    <img
                                        alt="..."
                                        src="https://cdn.midjourney.com/c04fd789-93ad-4f1e-92eb-3e91fce2d513/0_1.png"
                                        className="w-full align-middle rounded-lg hover:scale-105"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
