import {Auth} from "@supabase/auth-ui-react";
import {supabase} from "./supabase";
import {ThemeSupa} from "@supabase/auth-ui-shared";
import {useAppContext} from "../context/appContext";

export default function AuthPanel(props){
    const {session, setSession} = useAppContext();

    return (
        <div className="absolute top-16 right-0 overflow-y-auto h-auto w-full lg:w-1/5 py-4 px-10 bg-base-100 border-b lg:border-l border-base-content/10 lg:rounded-bl-xl z-50">
            <Auth
                className={"flex flex-col w-full h-full justify-center items-center"}
                supabaseClient={supabase}
                appearance={{theme: ThemeSupa}}
                theme="dark"
                providers={['google', 'discord']}
            />
        </div>
    )

}