import React, {useState} from "react";
import {getDutchDateTime} from "../utils/general";
import {Editor} from "@tinymce/tinymce-react";
import {useAppContext} from "../context/appContext";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";


export default function FeedPostModal(props) {
    const {setPosterOpen, GetFeed} = props;
    const {session, username, isAdmin} = useAppContext();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");

    async function PostFeed() {
        let newpost = {};
        newpost.posterName = username;
        newpost.title = title;
        newpost.content = message;
        const {error} = await supabase.from('newsfeed').insert({
            type: type,
            posterName: username,
            title: title,
            content: message,
        });
        if(error){
            toast.error('Failed to post article.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Posted news article successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
        setPosterOpen(false);
    }

    return (
        <div className={"flex flex-col w-full h-screen p-4"}>
            <div className="w-full px-4">
                <div
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-base-200">
                    <div className="flex-auto p-5 lg:p-10">
                        <label className="form-control w-full max-w-xs" htmlFor="type">
                            <select className="select select-bordered"
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.currentTarget.value);
                                    }}
                                    style={{transition: "all .15s ease"}}
                            >
                                <option disabled selected>Selecteer een artikel type</option>
                                <option>Aankondigingen</option>
                                <option>Bug Fixes</option>
                                <option>Prestatieverbeteringen</option>
                                <option>Diensten</option>
                                <option>Patch Notities</option>
                                <option>Boeiend Nieuws</option>
                            </select>
                        </label>
                        <div className="relative w-full mb-3 mt-8">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="title"
                            >
                                Titel
                            </label>
                            <input
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.currentTarget.value);
                                }}
                                type="text"
                                className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Titel van het Artikel"
                                style={{transition: "all .15s ease"}}
                            />
                        </div>
                        <Editor
                            apiKey={"hvzljhih4di59r6ymf02nbc49ucyc7litkskeomxj7n89xqk"}
                            value={message}
                            onEditorChange={(e) => setMessage(e)}
                            init={{
                                resize: true,
                                min_height: 800,
                                advcode_inline: true,
                                codesample_global_prismjs: true,
                                codesample_languages: [
                                    {text: 'HTML/XML', value: 'markup'},
                                    {text: 'JavaScript', value: 'javascript'},
                                    {text: 'CSS', value: 'css'},
                                    {text: 'PHP', value: 'php'},
                                    {text: 'Ruby', value: 'ruby'},
                                    {text: 'Python', value: 'python'},
                                    {text: 'Java', value: 'java'},
                                    {text: 'C', value: 'c'},
                                    {text: 'C#', value: 'csharp'},
                                    {text: 'C++', value: 'cpp'}
                                ],
                                images_upload_url: 'upload.php',
                                images_reuse_filename: true,
                                statusbar: false,
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table wordcount code',
                                toolbar: 'undo redo | fontfamily fontsize | bold italic underline strikethrough forecolor backcolor | link image media table | align lineheight | numlist bullist indent outdent | emoticons | removeformat | codesample code',
                            }}
                        />
                        <div className="text-center mt-6">
                            <button
                                className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                style={{transition: "all .15s ease"}}
                                onClick={() => {
                                    setPosterOpen(false);
                                }}
                            >
                                Annuleer
                            </button>
                            <button
                                className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                style={{transition: "all .15s ease"}}
                                onClick={() => {
                                    PostFeed();
                                }}
                            >
                                Plaats Artikel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}