import React, {useEffect, useRef, useState} from "react";
import { Slide } from "@mui/material";
import {ReportClick} from "../utils/gtag";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

export function HeroLandingSection1(props) {
    const { openPage } = useAppContext();
    const [visible, setVisble] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        let mc = document.querySelector("#maincontainer")
        if(mc!== null) mc.addEventListener("scroll", (e) => {
            setScrollPos(e.target.scrollTop);
        });
        return(() => {
            mc.removeEventListener("scroll", (e) => {
                setScrollPos(e.target.scrollTop);
            });
        })
    })

    useEffect(() => {
        if(!visible) {
            let target = document.querySelector("#herolandingsection1");
            if(target !== null) {
                if(scrollPos > (target.scrollHeight +50)) setVisble(true);
            }
        }
    }, [scrollPos]);

    return (
        <section className="container flex h-auto w-full py-8 lg:py-20" id="herolandingsection1">
                <div className="grid gap-5 lg:gap-40 xl:grid-cols-2 items-start justify-center">
                    <div>
                        <h1 className="text-2xl lg:text-3xl leading-relaxed font-bold">
                            DIGITALE MAATWERKOPLOSSINGEN VOOR EEN TECHNOLOGISCHE TOEKOMST
                        </h1>
                        <p className="text-lg leading-relaxed mt-4 mb-4">
                            Welkom bij Tech-Mania, waar uw digitale reis begint. Als u op zoek bent naar een
                            betrouwbare IT-partner in de regio Oost-Vlaanderen, die gespecialiseerd is in <Link to={"/website-op-maat"}
                            className="link-info link cursor-pointer text-primary font-semibold"
                            onClick={() => { ReportClick("op_maat_gemaakte_websites");}}>
                            op maat gemaakte websites</Link> en  <Link to={"/mobiele-app-laten-maken"}
                            className="link-info link text-primary font-semibold"
                            onClick={() => { ReportClick("mobiele_applicaties");
                            }}>mobiele applicaties</Link>, dan bent u bij ons aan het juiste
                            adres. Ons toegewijde team staat klaar om u te begeleiden en
                            te ondersteunen bij het realiseren van uw digitale ambities.
                        </p>
                        <p className="text-lg leading-relaxed mt-4 mb-4">
                            Of het nu gaat om het ontwikkelen van een gebruiksvriendelijke website voor uw bedrijf
                            of het creëren van een innovatieve mobiele applicatie, wij leveren <Link to={"/webdesignbureau-aalst"}
                            className="link-info link text-primary font-semibold"
                            onClick={() => ReportClick("oplossingen_die_perfect_aansluiten_bij_uw_behoeften_en_doelstellingen")}>
                            oplossingen die perfect aansluiten bij uw behoeften en doelstellingen</Link>. Onze focus ligt op het leveren
                            van hoogwaardige, op maat gemaakte oplossingen die niet alleen voldoen aan uw huidige
                            vereisten, maar ook klaar zijn voor de toekomst.
                        </p>
                    </div>

                    <div
                        className="flex items-center justify-center"
                    >
                        <Slide direction="left" in={visible} mountOnEnter unmountOnExit>
                            <img
                                alt="TMHeroSection1"
                                id="hero-image-hero-section-1"
                                className="w-full align-middle rounded-lg hover:scale-105"
                                src={"https://cdn.midjourney.com/924e67c8-bdda-42b7-8dda-fd6361dd1ef5/0_0.png"}
                            />
                        </Slide>
                    </div>
            </div>
        </section>
    );
};
