import {Button, Drawer, Menu, Navbar, useTheme} from "react-daisyui";
import {Menu as MenuIcon, } from "lucide-react";
import React, { useEffect, useState } from "react";
import ThemeWindow, {themes} from "./SettingsMenu";
import {UserMenu} from "../pages/UserMenu";
import {ReportClick} from "../utils/gtag";
import SettingsMenu from "./SettingsMenu";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import {Email, Phone} from "@mui/icons-material";
import TMLogo from "../assets/img/gertc_minimalistic_logo_TM.png";

const menuitems = [
    { id: "diensten", display: "DIENSTEN", sidebar: false },
    { id: "nieuws", display: "NIEUWS", sidebar: false },
    { id: "info", display: "INFO", sidebar: false },
    { id: "contact", display: "CONTACT", sidebar: false },
]
export function TopBar()  {
    const {session, setSession, authPanelOpen, setAuthPanelOpen, openPage, activeTheme, setActiveTheme, setShowSidebar, showSideBar} = useAppContext();
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const { setTheme } = useTheme();
    const [showSettings, setShowSettings] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        let mc = document.querySelector("#maincontainer")
        if(mc!== null) mc.addEventListener("scroll", (e) => {
            setScrollPos(e.target.scrollTop);
        });
        return(() => {
            mc.removeEventListener("scroll", (e) => {
                setScrollPos(e.target.scrollTop);
            });
        })
    })

    return (
        <>
            {/*PROMOTION BANNER (TEMP DISABLED)*/}
            {/*<div className="bg-orange-500 py-1.5 text-center text-xs text-neutral-content md:text-sm">
                <span className={"ml-2 mr-2"}>
                  Wees er vroeg bij en gebruik de code
                  <span className="mx-1 font-semibold text-warning">TECHMA24</span>
                  De eerstvolgende vijf klanten komen in aanmerking voor een gratis standaard website. Neem vandaag nog contact met ons op! 🚀
                </span>
            </div>*/}

            <div
                id="navbar-wrapper"
                className={"fixed w-full h-16 flex-row justify-center items-center top-0 z-10 border-b bg-base-100 lg:bg-opacity-95 lg:backdrop-blur-sm " + (scrollPos === 0 ? "border-transparent" : "border-base-content/10")}
            >
                <div className="container">
                    <Navbar className="px-0">
                        <Navbar.Start className="gap-2 lg:gap-2">
                            <div className="flex md:hidden lg:hidden lg:-ml-0">
                                <Drawer className={"z-50"}
                                        open={drawerOpened}
                                        onClickOverlay={() => setDrawerOpened(!drawerOpened)}
                                        side={
                                            <Menu
                                                className="min-h-full w-56 lg:gap-2 bg-base-100 p-2 text-base-content ">
                                                <Menu.Item className="font-medium">
                                                    <Link to={"/"} className="text-xl font-bold"
                                                          onClick={() => {
                                                              setShowSidebar(false);
                                                          }}>TECH MANIA</Link>
                                                </Menu.Item>
                                                <Menu.Item className="font-medium">
                                                    <Link to={"/"}
                                                          onClick={() => {
                                                              setShowSidebar(false);
                                                          }}>HOME</Link>
                                                </Menu.Item>

                                                {menuitems.map(el => (
                                                    <Menu.Item className={"font-medium"} key={el.id}>
                                                        <Link to={"/" + el.id}
                                                              onClick={() => setShowSidebar(el.sidebar)}>
                                                            {" " + el.display}
                                                        </Link>
                                                    </Menu.Item>
                                                ))}

                                                <SettingsMenu setActiveTheme={setActiveTheme}/>


                                            </Menu>
                                        }
                                >
                                    <Link to="/">
                                        <Button
                                            shape="square"
                                            color="ghost"
                                            onClick={() => {
                                                ReportClick("Login Drawer");
                                                setDrawerOpened(true);
                                            }}
                                        >
                                            <MenuIcon className="inline-block text-xl"/>
                                        </Button>
                                    </Link>
                                </Drawer>
                            </div>

                            <Link to={"/"}>
                                <div className={""}>
                                    {/*<div className={"flex"}>
                                        <img
                                            alt="TMLogo"
                                            id="tech-mania-logo"
                                            className="w-[5%] h-full align-middle"
                                            src={TMLogo}
                                        />
                                    </div>*/}
                                    <div
                                        className={"flex text-xl font-semibold" + (openPage() === "landing" ? " text-primary" : " text-secondary")}
                                        style={{width: "100%"}}
                                        color="ghost"
                                        onClick={() => {
                                            setShowSidebar(false);
                                        }}> TECH MANIA
                                        {/*<HomeIcon/>*/}

                                    </div>
                                </div>
                            </Link>


                        </Navbar.Start>

                        <Navbar.Center className="hidden lg:flex">
                            <Menu horizontal size="sm">

                            <Menu.Item
                                    className={"font-medium " + (openPage() === "landing" ? " text-primary" : " text-secondary")}>
                                    <Link to="/" onClick={() => {
                                        setShowSidebar(false);
                                    }}>HOME</Link>
                                </Menu.Item>

                                {menuitems.map(el => (
                                    <Menu.Item
                                        className={"font-medium mx-2" + (openPage() === el.id ? " text-primary" : " text-secondary")}
                                        key={el.id}>
                                        <Link to={"/" + el.id} onClick={() => { setShowSidebar(el.sidebar);}}>
                                                {" " + el.display}
                                            </Link>
                                        </Menu.Item>
                                ))}

                            </Menu>

                        </Navbar.Center>

                        <Navbar.End className="gap-0 lg:gap-5">

                            {/*---TEMP DISABLED---*/}
                            {/*<div className="hidden z-10 md:hidden lg:flex flex-col items-center mt-1">
                                <SettingsMenu />
                            </div>
                            <div className={"flex flex-row justify-end lg:mr-8 xl:mr-8"}>
                                <UserMenu />
                            </div>*/}

                            <Link to={"/contact"}>
                                <div className={"text-secondary text-l"}
                                     style={{width:"100%"}}
                                     color="ghost"
                                     onClick={() => {
                                         setShowSidebar(false);
                                     }}> {/*TECH MANIA*/}
                                    <Phone/>  +(32) 493 30 83 52
                                </div>
                            </Link>

                        </Navbar.End>
                    </Navbar>
                </div>
            </div>
        </>
    );
}