import {Feature} from "../components/Feature";
import {Feature2} from "../components/Feature2";
import React, {useState} from 'react';
import {Pricing} from "../components/Pricing";
import HeroWebsitePrijzenVergelijken from "../components/HeroWebsitePrijzenVergelijken";
import BreadCrumb from "../components/BreadCrumb";
import {ScrollToSection} from "../utils/general";
import {ReportClick} from "../utils/gtag";
import {Link} from "react-router-dom";
import {useAppContext} from "../context/appContext";


export default function Diensten(props) {
    const { openPage } = useAppContext();
    const [scrollPos, setScrollPos] = useState(0);

    return (
        <main className="grow mt-16">
            <div className={""}>
                <Feature />
                <hr className="hidden lg:flex my-1 border-primary"/>
                <Feature2 />
                {/*<Pricing/>*/}
                {/*<div className={"container"}>
                <hr className="my-1 border-primary"/>
            </div>*/}
                {/*<HeroWebsitePrijzenVergelijken/>*/}
            </div>
        </main>
    )
}

