export const Dutch = {
    LiveChat: {
        intotext: "Welkom bij de live chat.",
        logintext: "Vul een naam en email address in om een live chat te starten.",
        infotext: "De chat is gebaseerd op sessie en blijft active voor u tot u of een customer support medewerker het sluit.",
        username: "Gebruikers Naam",
        email: "Email Address",
        cancelbutton: "Sluiten",
        startbutton: "Start Chat",
        endchat: "Chat Afsluiten",
        endchattooltip: "Dit sluit de customer support live chat sessie, gesloten chats kunnen niet meer geopent worden.",
        liveChatButtonTooltip: "Open de live chat",
        staffmember: "Staff Member",
        staffresponse: "Staff Antwoord",
        customers: "Customer",
        customersresponse: "Custom Antwoord",
        selectchattooltip: "Selecteer chat channel",
        selecttickets: "Support Tickets",
        jumpbottom: "Jump naar het nieuwste bericht",
        sendmessage: "Verstuur bericht"
    }
}