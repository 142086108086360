import {useEffect, useState} from "react";
import {notify} from "../../components/notifications";
import {getDutchDateTime, getDutchDateTimeFromStamp} from "../../utils/general";

export function InventoryTiles(props) {
    const {categories, selectedItem, setSelectedItem, user, token, GetInventory, inventory} = props;
    const [newName, setNewName] = useState("New Name");
    const [newCat, setNewCat] = useState(categories.length > 0 ? categories[0].displayname : "None");
    const [newDescription, setNewDescription] = useState("New Description");

    function newItem() {
        let newitem = {};
        newitem.displayname = newName;
        newitem.category = newCat;
        newitem.description = newDescription;
        newitem.descriptionshort = newDescription;
        newitem.price = 0;
        newitem.images = [];
        newitem.extrafields = [];
        newitem.createdby = user.email;
        newitem.createdat = new Date().getTime();


        /*
        socket.emit("esadditem", {newitem: newitem, token: token}, (response) => {
            if (response.status === "success") {
                notify({
                    status: "success",
                    msg: "Category created successfully submitted."
                })
                setNewName("New Name");
                setNewCat(categories.length > 0 ? categories[0].displayname : "None");
                setNewDescription("New Description");
                GetInventory();
            }
        });

         */
    }

    return (
        <div className={"flex flex-col h-full w-full items-start justify-start overflow-y-auto overflow-x-hidden"}>
            <div className={"flex flex-wrap w-full items-start justify-start h-auto gap-4 p-4"}>
                {inventory && inventory.map((item, ind) => (
                    <div key={item._id} onClick={() => {
                        setSelectedItem(item);
                    }}
                         className={"flex flex-col w-64 h-64 cursor-pointer gap-2 items-center justify-center rounded-xl border border-base-100 hover:bg-opacity-80 " + (selectedItem === item ? "bg-secondary" : "bg-base-200")}>
                        <p className={"text-lg font-bold"}>{item.displayname}</p>
                        <p className={"text-xs italic"}>{item.type}</p>
                        <p className={"text-sm"}>{item.description}</p>
                    </div>
                ))}
                <div className={"flex flex-col w-64 h-64 justify-center items-center  text-white rounded-xl border border-base-100 bg-green-950"}>
                    <div key={"newcat"} className={"flex flex-col w-full h-auto items-center justify-center gap-2 px-2"}>
                        <input className={"input input-xs input-bordered w-full"} type={"text"} value={newName}
                               onChange={(e) => {
                                   setNewName(e.currentTarget.value);
                               }}/>
                        <select value={newCat} onChange={(e) => {
                            setNewCat(e.currentTarget.value);
                        }} className="select select-xs select-bordered w-full">
                            {categories.map((cat, ind) => (<option key={ind}>{cat.displayname}</option>))}
                        </select>
                        <textarea className={"textareatickets input input-xs input-bordered w-full h-12 p-2"}
                                  rows={3} value={newDescription} onChange={(e) => {
                            setNewDescription(e.currentTarget.value);
                        }}/>
                        <div className={"btn btn-ghost bg-green-600 btn-xs"} onClick={() => {
                            newItem();
                        }}>Create New
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ItemEdit(props) {
    const {selectedItem, setSelectedItem, user, token, GetInventory, categories, inventory} = props;
    const [tempName, setTempName] = useState("");
    const [tempCat, setTempCat] = useState("");
    const [tempPrice, setTempPrice] = useState(0);
    const [tempDescription, setTempDescription] = useState("");
    const [tempSDescription, setTempSDescription] = useState("");
    const [tempImages, setTempImages] = useState([]);

    function DeleteCategory(category) {
        /*
        socket.emit("esdeleteitem", {id: category._id, token: token}, (response) => {
            if (response.status === "success") {
                setSelectedItem(null);
                GetInventory();
                notify({
                    status: "success",
                    msg: "Inventory item successfully deleted."
                })
            } else {
                notify({
                    status: "failed",
                    msg: "Inventory item failed to delete."
                })
            }
        });
         */
    }

    function SubmitEdit() {
        /*
        socket.emit("esedititems", {
            id: selectedItem._id,
            token: token,
            changes: {
                displayname: tempName,
                description: tempDescription,
                category: tempCat,
                descriptionshort: tempSDescription,
                price: tempPrice,
                images: [],
                extrafields: []
            }
        }, (response) => {
            if (response.status === "success") {
                GetInventory();
                notify({
                    status: "success",
                    msg: "Feed post successfully edited."
                })
            } else {
                notify({
                    status: "failed",
                    msg: "Feed post failed to edit."
                })
            }
        });
         */
    }

    useEffect(()=> {
        if(selectedItem !== null){
            for(let i = 0; i < inventory.length; i++){
                if(selectedItem._id === inventory[i]._id){
                    setSelectedItem(inventory[i]);
                    break;
                }
            }
        }
    },[inventory])

    useEffect(() => {
        if (selectedItem !== null) {
            setTempName(selectedItem.displayname);
            setTempCat(selectedItem.category);
            setTempDescription(selectedItem.description);
            setTempSDescription(selectedItem.descriptionshort);
            setTempPrice(selectedItem.price);
        }
    }, [selectedItem])

    if(selectedItem === null) return (
        <div className={"flex flex-col w-[85%] h-full items-center justify-start bg-base-300 pt-10"}></div>);
    else return (
        <div className={"flex flex-col w-[85%] h-full items-center justify-start bg-base-300 pt-10"}>
            <div className={"flex flex-col w-[85%] h-auto justify-center items-center gap-6"}>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Item Name</p>
                    <input className={"input input-bordered w-full"} type={"text"} value={tempName} onChange={(e) => {
                        setTempName(e.currentTarget.value);
                    }}/>
                </div>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Price</p>
                    <input className={"input input-bordered w-full"} type={"number"} value={tempPrice}
                           onChange={(e) => {
                               setTempPrice(e.currentTarget.value);
                           }}/>
                </div>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Item Category</p>
                    <select value={tempCat} onChange={(e) => {
                        setTempCat(e.currentTarget.value);
                    }} className="select select-bordered w-full">
                        {categories.map((cat, ind) => (<option key={ind}>{cat.displayname}</option>))}
                    </select>
                </div>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Short Description</p>
                    <input className={"input input-bordered w-full"} type={"text"} value={tempSDescription}
                           onChange={(e) => {
                               setTempSDescription(e.currentTarget.value);
                           }}/>
                </div>

                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Description</p>
                    <textarea className={"textareatickets input input-bordered w-full h-24 max-h-46 p-2"} rows={3}
                              value={tempDescription} onChange={(e) => {
                        setTempDescription(e.currentTarget.value);
                    }}/>
                </div>

                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Images</p>
                    <form className={"flex flex-row h-auto w-full"} action="upload.php" method="post" encType="multipart/form-data">
                        <input className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            id="small_size" type="file"/>
                        <button type="submit">Add</button>
                    </form>
                </div>

                <div className={"text-xs"}>{selectedItem._id}</div>
                <div className={"text-xs"}>{"Created by: " + selectedItem.createdby}</div>
                <div className={"text-xs"}>{"Created at: " + getDutchDateTimeFromStamp(selectedItem.createdat)}</div>
            </div>
            <div className={"flex flex-row w-full justify-around items-end h-36"}>
                <div className={"btn btn-error w-24"} onClick={() => {
                    DeleteCategory(selectedItem);
                }}>Delete
                </div>
                <div className={"btn btn-ghost bg-green-600 w-24"} onClick={() => {
                    SubmitEdit();
                }}>Edit
                </div>
            </div>
        </div>
    )
}