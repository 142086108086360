import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {Button} from "react-daisyui";
import {ReportClick} from "../../utils/gtag";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";

export default function EenMobieleAppLatenMakenBiedtOneindigVeelMogelijkheden(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[415px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> EEN MOBIELE APP LATEN MAKEN BIEDT ONEINDIG VEEL MOGELIJKHEDEN </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Wanneer u besluit een mobiele app te laten maken, opent u de deur naar een wereld van
                        mogelijkheden.
                        Of het nu gaat om het verbeteren van klantenservice,
                        het vergroten van gebruikersbetrokkenheid of het optimaliseren van interne bedrijfsprocessen,
                        de ontwikkeling van een mobiele app kan u helpen bij diverse functies.
                        De creatie van een mobiele app door <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("ons_webdesignbureau");
                        }}>
                        ons webdesignbureau</a></Link> zorgt ervoor dat uw app niet alleen
                        functioneel is,
                        maar ook aantrekkelijk en gebruiksvriendelijk.
                        De ontwikkeling van een mobiele app bij ons betekent dat u kunt rekenen op maatwerk dat
                        perfect aansluit bij de behoeften en wensen van uw onderneming.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[394px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[394px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/b43a2395-d072-4ea8-a35d-161d75758c98/0_1.png"}
                    />
                </div>
            </div>
        </div>
)
}