import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import {notify} from "../components/notifications";
import {ScrollToBottom} from "./utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {getDutchDateTimeFromStamp} from "../utils/general";
import SendIcon from "@mui/icons-material/Send";
import secureLocalStorage from "react-secure-storage";

const defaultName = "Gebruiker" + randomNumber(0,10000).toString();

function randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function ChatNotLoggedIn(props) {
    const {setChatOpen, localization, GetAnomTicket, anomTicket, SetTicketID} = props;
    const [userName, setUserName] = useState(secureLocalStorage.getItem("anomName") === null ? defaultName : secureLocalStorage.getItem("anomName"));
    const [email, setEmail] = useState(secureLocalStorage.getItem("anomEmail") === null ? "" : secureLocalStorage.getItem("anomEmail"));
    const [scrollPos, setScrollPos] = useState(0);
    const [responseText, setResponseText] = useState("");
    const [notBottom, setNotBottom] = useState(false);

    function StartNewticket(){

        /*
        socket.emit("anomticket", {email: email, username: userName}, (response) => {
            if (response.status === "success") {
                SetTicketID(response.ticketid);
            } else {
                notify({
                    status: "failed",
                    msg: "Failed to create."
                });
            }
        });

         */
    }

    function SendMessageTicket(){
        /*
        socket.emit("anomticketmessage", {ticket: anomTicket, message: responseText, email: email, username: userName}, (response) => {
            if (response.status === "success") {
                GetAnomTicket();
            } else {
                notify({
                    status: "failed",
                    msg: "Failed to respond."
                });
            }
        });
        setResponseText("");

         */
    }

    useEffect(()=> {
        if(anomTicket !== null && anomTicket.messages.length > 3) ScrollToBottom();
    }, [anomTicket])

    useEffect(() => {
        const tc = document.querySelector("#messagecontainer");
        if(anomTicket !== null) {
            let so = tc.scrollHeight - tc.scrollTop;
            //   console.log(tc.scrollTop  + " vs " + so);
            if(tc.scrollTop >= so) setNotBottom(true);
            else setNotBottom(false);
        }
    }, [scrollPos]);

    return (
        <div className={"fixed flex flex-row justify-center items-center h-2/3 lg:h-1/2 w-[97%] lg:w-1/3 text-sm rounded-xl overflow-hidden bg-base-300 border border-base-200 right-4 bottom-0 z-40"}>
            <>
                {anomTicket === null ?
            <div className={"flex flex-col items-center justify-start h-full text-sm lg:text-md w-full overflow-hidden"}>
                <div className={"flex flex-row w-full items-center justify-end p-2 h-12 gap-4 bg-base-200"}>
                    <div data-tip={localization.LiveChat.cancelbutton}
                         className={"flex btn btn-xs btn-base-200 h-8 w-8 lg:h-12 lg:w-12 tooltip tooltip-left"}
                         onClick={() => {
                             setChatOpen(false);
                         }}><Close/>
                    </div>
                </div>
                <div
                    className={"flex flex-col h-full w-full overflow-hidden items-center justify-start p-10 gap-2 text-sm lg:text-lg"}>
                    <p className={"flex flex-row w-full h-auto justify-center items-center font-bold"}>{localization.LiveChat.intotext}</p>
                    <p className={"flex flex-row w-full h-auto justify-center items-center"}>{localization.LiveChat.logintext}</p>
                    <br/>
                    <div className={"flex flex-col justify-center items-center w-full max-w-md h-auto gap-2"}>
                        <p>{localization.LiveChat.username}</p>
                        <input className={"input input-bordered w-full"} type={"text"} value={userName}
                               onChange={(e) => {
                                   setUserName(e.currentTarget.value);
                                   secureLocalStorage.setItem("anomName",e.currentTarget.value);
                               }}/>
                    </div>
                    <div className={"flex flex-col justify-center items-center w-full max-w-md h-auto gap-2"}>
                        <p>{localization.LiveChat.email}</p>
                        <input className={"input input-bordered w-full"} type={"email"} value={email}
                               onChange={(e) => {
                                   setEmail(e.currentTarget.value);
                                   secureLocalStorage.setItem("anomEmail", e.currentTarget.value);
                               }}/>
                    </div>
                    <p className={"flex flex-row w-full h-14 justify-center items-center text-sm text-center italic"}>{localization.LiveChat.infotext}</p>
                    <br/><br/>
                    <div className={"flex flex-row h-20 w-full justify-around items-center"}>
                        <div className={"flex flex-row justify-center items-center w-32 h-12 gap-2 btn btn-error"}
                             onClick={() => {
                                 setChatOpen(false);
                             }}>
                            {localization.LiveChat.cancelbutton}
                        </div>
                        <div className={"flex flex-row justify-center items-center w-32 h-12 gap-2 btn btn-primary"}
                             onClick={() => {
                                 if(userName.length > 4 && email.length > 4 && email.includes("@") && email.includes(".")){
                                     StartNewticket();
                                 }
                             }}>
                            {localization.LiveChat.startbutton}
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className={"flex flex-col items-center justify-start h-full text-sm lg:text-md w-full overflow-hidden"}>
                <div className={"flex flex-row h-full w-full pt-2 pl-2 overflow-hidden"}>
                    <div id="messagecontainer" onScroll={(e) => { setScrollPos(e.target.scrollTop); }}
                         className={"flex flex-col items-start justify-start h-full w-full overflow-y-auto overflow-x-hidden bg-base-100 rounded-t-xl"}>
                        <div className={"flex flex-col items-start gap-4 justify-start h-auto w-full py-2 lg:py-4 px-4 lg:px-8"}>
                            {anomTicket && anomTicket.messages.map((message, ind) => (
                                <div id={"msg" + ind} key={"msg" + ind}
                                     className={"flex flex-col w-[80%] h-auto p-2 lg:p-4 rounded-xl bg-opacity-80 " + (!message.staffresponse ? " bg-secondary" : " bg-base-300 self-end")}>
                                    <div className={"flex-col w-full h-auto items-end text-xs px-2 justify-start"}>
                                        <div className={"flex flex-row gap-3 w-full h-auto " + (message.staffresponse ? "justify-end" : "justify-start")}>
                                            <p className={"text-sm lg:text-md font-bold"}>{message.staffresponse &&
                                                <AdminPanelSettingsIcon/>}{message.displayname + " <" + (message.staffresponse ? "info@tech-mania.be" : message.sender) + ">"}</p>
                                        </div>
                                        <div className={"flex flex-row justify-start items-start italic w-full h-auto " + (message.staffresponse ? "justify-end" : "justify-start")}>
                                            {(message.staffresponse ? localization.LiveChat.staffresponse : localization.LiveChat.customersresponse)}
                                        </div>
                                    </div>
                                    <div className={"flex-wrap h-auto w-full text-sm lg:text-md px-2 py-4"}>
                                        {message.message}
                                    </div>
                                    <p className={"flex flex-row text-xs items-center w-full justify-end"}>{getDutchDateTimeFromStamp(message.date)}</p>
                                </div>
                            ))}
                            <div id={"messagesend"} className={"h-1 w-1"}></div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-start justify-start h-20 w-full pl-2 pb-2 overflow-hidden"}>
                    <div className={"flex flex-col w-full h-full bg-base-100 rounded-b-xl py-1 pr-4 pl-3"}>
                        <div className={"flex w-full h-full items-center justify-center gap-2 flex-row py-2"}>
                            <textarea value={responseText} rows={2}
                                      onChange={(e) => {
                                          setResponseText(e.currentTarget.value)
                                      }} className={"textareatickets flex bg-base-content rounded-xl text-black w-full h-full p-2"}/>
                            <div className={"btn btn-secondary btn-md flex flex-row w-auto h-auto justify-center items-center tooltip tooltip-left"}
                                 onClick={() => {
                                     SendMessageTicket();
                                 }} data-tip={localization.LiveChat.sendmessage}>
                                <SendIcon size={"small"}/></div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    </div>
    )
}

function ChatBar(props) {
    const {setChatOpen, localization} = props;
    return (
        <div data-tip={localization.LiveChat.endchattooltip}
             className={"flex tooltip tooltip-right flex-row justify-center items-center w-32 h-12 gap-2 btn btn-error btn-xs"}
             onClick={() => {
                 setChatOpen(false);
             }}>
            {localization.LiveChat.endchat}
        </div>
    )
}