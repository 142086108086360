export const defaultdescription =  "Tech-Mania is een dynamisch bedrijf gevestigd in Oost-Vlaanderen, gespecialiseerd in het ontwikkelen van op maat gemaakte websites en mobiele applicaties.";

export const routes = [
    {pname: "landing", displayname: "Landing", description: "", route: "/"},
    {pname: "info", displayname: "Over Ons", description: "Test dit description override", route: "/info"},
    {pname: "diensten", displayname: "Onze Diensten", description: "", route: "/diensten"},
    {pname: "contact", displayname: "Contact Ons", description: "", route: "/contact"},
    {pname: "resetpw", displayname: "Reset Paswoord", description: "", route: "/resetpw"},
    {pname: "nieuws", displayname: "Laatste Nieuws", description: "", route: "/nieuws"},
    {pname: "webdesignbureau-aalst", displayname: "Webdesign Bureau Aalst", description: "", route: "/webdesignbureau-aalst"},
    {pname: "website-op-maat", displayname: "Website op Maat", description: "", route: "/website-op-maat"},
    {pname: "appontwikkelaar", displayname: "App Ontwikkelaar", description: "", route: "/appontwikkelaar"},
    {pname: "programmeur-aalst", displayname: "Programmeur in Aals", description: "", route: "/programmeur-aalst"},
    {pname: "app-maken-van-website", displayname: "App maken van Website", description: "", route: "/app-maken-van-website"},
    {pname: "back-end-developer-aalst", displayname: "Backend Developer Aals", description: "", route: "/back-end-developer-aalst"},
    {pname: "mobiele-app-laten-maken", displayname: "Mobiele Apps Maken", description: "", route: "/mobiele-app-laten-maken"},
    {pname: "admin", displayname: "Administratie", description: "", route: "/admin"}
]


export function GetRoutePage(str){
    for(let i = 0; i < routes.length; i++){
        let route = routes[i];
        if(route.pname === str){
            return route;
        }
    }
    return routes[0];
}

export function GetRoute(str){
    for(let i = 0; i < routes.length; i++){
        let route = routes[i];
        if(route.route === str){
            return route;
        }
    }
    return routes[0];
}