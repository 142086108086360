import React from "react";
import vectorImage from "../assets/img/Vector.svg";


export default function LinkBanner() {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col lg:flex-row w-full h-auto justify-center items-start lg:items-center gap-2 lg:gap-8">

                <div className="flex flex-row w-auto h-12 items-center gap-2 hover:scale-105">
                    <div className={"flex flex-col w-6 h-6 bg-primary rounded-2xl justify-center items-center"}>
                        <img
                            alt=""
                            id=""
                            className="w-2/5 align-middle"
                            src={vectorImage}
                        />
                    </div>
                    <div>
                        <span className=""> Website op maat </span>

                    </div>
                </div>
                <div className="flex flex-row w-auto h-12 items-center gap-2 hover:scale-105">
                    <div className={"flex flex-col w-6 h-6 bg-primary rounded-2xl justify-center items-center"}>
                        <img
                            alt=""
                            id=""
                            className="w-2/5 align-middle"
                            src={vectorImage}
                        />
                    </div>
                    <div>
                        <span className=""> Innovatieve mobiele apps </span>
                    </div>
                </div>
                <div className="flex flex-row w-auto h-12 items-center gap-2 hover:scale-105">
                    <div className={"flex flex-col w-6 h-6 bg-primary rounded-2xl justify-center items-center"}>
                        <img
                            alt=""
                            id=""
                            className="w-2/5 align-middle"
                            src={vectorImage}
                        />
                    </div>
                    <div>
                        <span className=""> Gebruik van nieuwste technologieën </span>
                    </div>
                </div>
            </div>
        </div>
    )
}