import React from "react";
import {GetRoutePage} from "../../utils/routes";


export default function ImageBanner3(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-row w-full h-auto bg-base-100 justify-between items-center gap-2 lg:gap-2 xl:gap-0">
                <div className={"flex flex-col w-[400px] xl:h-[400px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="w-full align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/89117e37-d322-4ffe-a651-189d8d462615/0_1.png"}
                    />
                </div>
                <div className={"flex flex-col w-[400px] xl:h-[400px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="w-full align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/924e67c8-bdda-42b7-8dda-fd6361dd1ef5/0_0.png"}
                    />
                </div>
                <div className={"flex flex-col w-[400px] xl:h-[400px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="w-full align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/7935b1aa-74d8-47ce-97af-d735bd6b601c/0_0.png"}
                    />
                </div>
            </div>
        </div>
    )
}