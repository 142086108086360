import React from "react";
import {Button} from "react-daisyui";
import {ReportClick} from "../../utils/gtag";
import SendIcon from "@mui/icons-material/Send";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function ProgrammeurInAalst(props) {

    return (
        <div className={"pb-8 lg:pb-10"}>
            <div className="flex flex-col lg:w-full xl:flex-row xl:w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full xl:w-[615px] lg:mb-16 mb-10 xl:mb-0"}>
                    <div>
                        <h1 className="xl:w-[459px] xl:h-[146px] text-4xl font-black lg:text-6xl lg:leading-none mt-2">
                            <span className="text-primary"> PROGRAMMEUR IN AALST </span>
                        </h1>
                    </div>
                    <div>
                        <p className="mt-8 text-lg xl:w-[615px] xl:h-[224px]">
                            Tech Mania uit Aalst staat voor u klaar als ervaren webdesignbureau om uw digitale
                            aanwezigheid naar een hoger niveau te tillen. Ons team bestaat uit deskundige
                            webdevelopers die met hun technische expertise zorgen voor een solide en functionele
                            basis van uw website. Elke webontwikkelaar in ons team brengt creativiteit en
                            innovatie in het ontwerpproces. Daarnaast zetten onze ervaren websitebouwers zich in
                            voor de technische realisatie en implementatie van uw online platform.
                        </p>
                    </div>
                </div>
                <div
                    className={"flex flex-col xl:w-[394px] h-auto lg:h-[318px] xl:h-[418px] border-t border-b border-primary border-1 justify-center items-center"}>
                    <div className="flex flex-col lg:w-5/6 px-4 mr-auto ml-auto py-8">
                        <h3 className="text-1xl mb-2 font-bold">
                            MEER WETEN OVER ONS?
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                            Meer weten over ons en wat we voor jouw bedrijf kunnen betekenen? Neem gerust contact
                            met ons op voor een kennismakingsgesprek. We kijken ernaar uit om jouw digitale ambities
                            te realiseren. </p>
                        <div className={"mt-6"}>
                            <div className="">
                                <Link to={"/diensten"}>
                                    <Button color="primary" onClick={(e) => {
                                        ReportClick("bekijk_ons_aanbod_on_webdesignbureau-aalst");
                                    }} endIcon={<SendIcon/>}>Bekijk ons aanbod</Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}