import {toast} from "react-toastify";

export function notify(resp) {
    switch (resp.status) {
        case "success":
            toast.success(resp.msg, {
                position: "bottom-right",
                pauseOnFocusLoss: false,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            break;
        case "error":
            toast.error(resp.msg, {
                position: "bottom-right",
                pauseOnFocusLoss: false,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            break;
        case "warning":
            toast.warning(resp.msg, {
                position: "bottom-right",
                pauseOnFocusLoss: false,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            break;
        case "friendly":
            toast.info(resp.msg, {
                position: "bottom-right",
                pauseOnFocusLoss: false,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            break;
        default:
            toast.info(resp.msg, {
                position: "bottom-right",
                pauseOnFocusLoss: false,
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            break;
    }
}