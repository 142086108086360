export function ReportClick(buttonName){
    window.dataLayer.push({'event': 'button_clicked', 'button_name': buttonName.toString() });
}

export function ReportOutgoingClick(buttonName, destination){
    window.dataLayer.push({'event': 'outgoing_clicked', 'button_name': buttonName.toString(), 'destination': destination.toString() });
}

export function ReportLogin(status){
    window.dataLayer.push({'event': 'login', 'loginstatus': status.toString() });
}
