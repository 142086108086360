
import React, {useState} from "react";
import DevicesIcon from "@mui/icons-material/Devices";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WebIcon from "@mui/icons-material/Web";
import {useAppContext} from "../context/appContext";
import {ScrollToSection} from "../utils/general";
import {ReportClick} from "../utils/gtag";
import {Link} from "react-router-dom";

export default function HeroLandingServices(props) {
    const { openPage } = useAppContext();
    const [scrollPos, setScrollPos] = useState(0);

    return (
        <section className="flex flex-col w-full h-auto bg-gradient-to-r from-cyan-500 to-blue-500">
            <div className="container -mt-1 py-8 lg:py-20">
                <div className="flex flex-wrap">


                    <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("opmaatgemaaktewebsites");
                                  ReportClick("diensten_opmaatgemaaktewebsites");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <DevicesIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">OP MAAT GEMAAKTE WEBSITES</h6>
                                <p className="mt-2 mb-4 text-lg px-2 text-base-100 text-shadow-lg">
                                    Van eenvoudige informatieve websites tot complexe e-commerceplatforms, wij
                                    ontwikkelen websites die naadloos aansluiten bij uw merkidentiteit en
                                    functionaliteiten bieden die uw doelgroep aanspreken.
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>


                    <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("mobieleapplicatieontwikkeling");
                                  ReportClick("diensten_mobieleapplicatieontwikkeling");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <PhoneIphoneIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">MOBIELE APPLICATIE ONTWIKKELING</h6>
                                <p className="mt-2 mb-11 text-lg px-2 text-base-100 text-shadow-lg">
                                    Wij creëren innovatieve mobiele applicaties voor zowel iOS als Android, waarmee
                                    u uw klanten kunt betrekken, uw bedrijfsprocessen kunt stroomlijnen en nieuwe
                                    zakelijke kansen kunt ontdekken.
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>

                    <div className="lg:pt-12 pt-6 w-full lg:w-4/12 px-4 text-center hover:scale-105">
                        <Link to={"/diensten"}
                              onClick={(e) => {
                                  ScrollToSection("webapplicatieontwikkeling");
                                  ReportClick("diensten_webapplicatieontwikkeling");
                              }}>
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-secondary-content/5 w-full mb-8 shadow-lg hover:shadow-xl rounded-lg border-primary border-2">
                            <div className="px-4 py-5 leading-relaxed">
                                <div
                                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-primary">
                                    <i className="fas fa-award"></i>
                                    <WebIcon sx={{fontSize: 34}}/>
                                </div>
                                <h6 className="text-xl font-bold text-secondary-content text-shadow-lg">WEBAPPLICATIE ONTWIKKELING</h6>
                                <p className="mt-2 mb-11 text-lg px-2 text-base-100 text-shadow-lg">
                                    Of het nu gaat om het automatiseren van interne processen of het creëren van
                                    klantgerichte tools, wij ontwikkelen op maat gemaakte webapplicaties die uw
                                    bedrijf efficiënter maken.
                                </p>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>

            </div>
        </section>
    );
};
