import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function DeVoordelenVanEenAppMakenVanUwWebsite(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> DE VOORDELEN VAN EEN APP MAKEN VAN UW WEBSITE </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Een app maken van uw website biedt talloze voordelen. Dit initiatief kan de gebruikservaring aanzienlijk verbeteren,
                        de betrokkenheid verhogen en zelfs zorgen voor een nieuwe bron van inkomsten.
                        Door <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link text-primary font-semibold"
                        onClick={() => { ReportClick("de_ervaring_van_ons_webdesignbureau");
                        }}>de ervaring van ons webdesignbureau</a></Link> blijft de essentie van uw merk
                        behouden tijdens de digitale transformatie.
                        Een <Link to={"/mobiele-app-laten-maken"}><a
                        className="link-info link text-primary font-semibold"
                        onClick={() => {
                            ReportClick("mobiele_app_laten_maken");
                        }}>mobiele app laten maken</a></Link> biedt uw klanten het gemak van directe
                        toegang en interactie met uw diensten, ongeacht waar ze zich bevinden.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/3f50ed07-3aa7-4088-8dca-7fcd0518d01f/0_3.png"}
                    />
                </div>
            </div>
        </div>
    )
}