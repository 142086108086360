import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import React, {useState} from "react";
import {Button} from "react-daisyui";
import SendIcon from "@mui/icons-material/Send";
import {notify} from "../components/notifications";
import {ReportClick} from "../utils/gtag";
import {getDutchDateTime, ScrollToSectionFast} from "../utils/general";
import BreadCrumb from "../components/BreadCrumb";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";
import axios from "axios";

export default function ContactPage(props){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    async function SubmitDiscord(){
        const webhookURL = 'https://discord.com/api/webhooks/1238494315593076786/rhb7-FcTc_XUqYpp4Qa3K8JS4E6ZtO217VjdI0Zgh3_nYIXfeHs12amJzGIiP7RbMe6B';

        const embed = {
            color: 0x0099ff,
            title: 'Nieuwe Contact Submissie bericht:',
            url: 'https://tech-mania.be',
            author: {
                name: "TechMania Support",
                icon_url: "https://tech-mania.be/static/media/gertc_minimalistic_logo_TM.3249c2c2d6f55db387cc.png",
                url: 'https://tech-mania.be',
            },
            description: message,
            fields: [
                { name: '\u200B', value: '\u200B' },
                { name: 'Naam', value: name },
                { name: 'Email', value: email },
            ],
            timestamp: new Date().toISOString(),
            footer: {
                text: 'Tech-Mania contact form submission',
                iconURL: ""
            },
        };

        try {
            await axios.post(webhookURL, {username: "TechMania Support",
                avatarURL: "https://tech-mania.be/static/media/gertc_minimalistic_logo_TM.3249c2c2d6f55db387cc.png",
                embeds: [embed], });
        } catch (error) {
            console.error('Error sending message to Discord:', error);
            toast.error('Er is iets fout gegaan bij de verzending van je formulier, probeer het later nog eens.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    async function SendForm(){
        if(!CanSend(true)) return;
        ReportClick("Verzend Bericht via Contact");
        try {
            let msges = [{
                sender: email,
                displayname: name,
                message: message,
                date: getDutchDateTime(),
                staffresponse: false
            }];
            const {error} = await supabase.from('supporttickets').insert({
                    type: "ticket",
                    public: false,
                    channelname: email,
                    messages: msges,
                    participants: [ email ],
                    status: "open"
                });
            await SubmitDiscord();
            setName("");
            setEmail("");
            setMessage("");
            toast.success('Contact formulier succesvol verzonden, we contacteren jou zo snel mogelijk terug.', {
                duration: 4000,
                position: 'bottom-right',
            });
        } catch (error) {
            console.log("Error occurred", { error });
            toast.error('Er is iets fout gegaan bij de verzending van je formulier, probeer het later nog eens.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    function CanSend(noti){
        if(name.length < 4){
           if(noti) notify({
                status: "error",
                msg: "Te korte naam ingevuld.",
            });
            return false;
        }
        if(email.length < 4 || !email.indexOf("@")){
            if(noti) notify({
                status: "error",
                msg: "Ongeldige email ingevuld.",
            });
            return false;
        }
        if(message.length < 10){
            if(noti) notify({
                status: "error",
                msg: "Te korte bericht ingevuld.",
            });
            return false;
        }
        return true;
    }

    return (
        <div className={"container mt-16"}>
            <section id={"contactsection"}>
                <div className={""}>
                    <div className={"flex flex-wrap items-center justify-center py-8 lg:py-20 lg:mr-20"}>

                        <div className="w-full md:w-5/12 px-4 text-center lg:text-end lg:mb-5 mb-auto">
                            <div className="w-full px-4 ">
                <span className="block uppercase text-lg font-semibold mb-10 mt-5">
                  Contacteer ons
                </span>
                                <ul className="list-unstyled">
                                    <div>
                                        <div className="block pb-2 text-2xl mb-10"
                                           href="https://tech-mania.be/contact"> Tech Mania
                                        </div>
                                    </div>
                                    <div>
                                        <div className="block pb-2 text-2xl mb-10"
                                           href="https://tech-mania.be/contact">Kareelstraat 130B
                                            <p>9300 Aalst</p>
                                            <p>België</p>
                                        </div>
                                    </div>
                                    <li>
                                        <a className="block text-2xl">
                                            T:
                                            <b className="hover:text-primary-content font-semibold pb-2 text-2xl ml-2"
                                               href="https://www.tech-mania.be"> +32 493 30 83 52
                                            </b>
                                        </a>

                                        <a className="block text-2xl">
                                            E:
                                            <b className="hover:text-primary-content font-semibold pb-2 text-2xl ml-2"
                                               href="https://www.tech-mania.be"> info@tech-mania.be
                                            </b>
                                        </a>

                                        <a className="block text-2xl mb-10">
                                            W:
                                            <b className="hover:text-primary-content font-semibold pb-2 text-2xl ml-2"
                                               href="https://www.tech-mania.be"> www.tech-mania.be
                                            </b>
                                        </a>
                                    </li>
                                    <div>
                                        <div className="block pb-2 text-2xl mb-10"
                                           href="https://tech-mania.be/contact"> BE 0798.806.480
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>

                        <div className="flex md:w-6/12 h-auto container px-4 items-start justify-start my-auto">
                            <div className="flex items-center justify-center">
                                <div className="w-full px-4">
                                    <div
                                        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                        <div className="flex-auto p-5 lg:p-10">
                                            <h4 className="text-gray-600 text-2xl">
                                                Heeft u een vraag?
                                            </h4>
                                            <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                                Vul dit in en we zullen u spoedig beantwoorden.
                                            </p>
                                            <div className="relative w-full mb-3 mt-8">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="full-naam"
                                                >
                                                    Naam
                                                </label>
                                                <input
                                                    value={name}
                                                    onChange={(e) => {setName(e.currentTarget.value);}}
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Volledige Naam"
                                                    style={{transition: "all .15s ease"}}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    value={email}
                                                    onChange={(e) => {setEmail(e.currentTarget.value);}}
                                                    type="email"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Email"
                                                    style={{transition: "all .15s ease"}}
                                                />
                                            </div>

                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="message"
                                                >
                                                    Bericht
                                                </label>
                                                <textarea
                                                    value={message}
                                                    onChange={(e) => {setMessage(e.currentTarget.value);}}
                                                    rows="4"
                                                    cols="80"
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    placeholder="Schrijf hier uw bericht..."
                                                />
                                            </div>
                                            <div className="text-center mt-6">
                                                <button
                                                    className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="button"
                                                    //disabled={!CanSend(false)}
                                                    style={{transition: "all .15s ease"}}
                                                    onClick={() => { SendForm(); }}
                                                >
                                                    Verzend Bericht
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <div className={""}>
                <hr className="my-6 border-primary"/>
            </div>

            <section id={"webdesignaalstsection"}>
                <div className={""}>
                    <div className="flex flex-wrap items-center py-8 lg:py-20">
                        <div className="w-full px-4 mr-auto ml-auto text-lg">
                            {/*<div
                                className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                                <i className="fas fa-user-friends text-xl"></i>
                            </div>*/}
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                WEBDESIGN AALST
                            </h3>
                            <p className="text-lg leading-relaxed mt-4 mb-4">
                                Ben je op zoek naar professioneel webdesign in Aalst of de nabije regio?
                                Bij Tech Mania helpen we je graag. We staan klaar om jouw website of web- en mobiele
                                applicatie
                                op maat te ontwerpen, precies zoals jij het wilt.
                                Daarnaast bieden we ook ondersteuning bij het ontwerpen van een logo of visitekaartje,
                                waardoor je direct een professionele uitstraling voor jouw bedrijf creëert.
                            </p>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                WEBDESIGN BUREAU UIT OOST-VLAANDEREN
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                                Bent u op zoek naar professioneel webdesign in Oost-Vlaanderen?
                                Dan bent u bij ons aan het juiste adres.
                                Hoewel ons bedrijf gevestigd is in Aalst,
                                strekt onze dienstverlening zich uit tot ver buiten deze regio.

                                Wij hechten veel waarde aan een persoonlijke aanpak,
                                uitmuntende service en het vermogen om met onze klanten mee te denken.
                                Bij Tech Mania staat webdesign in Aalst op zijn best.
                                Wij gaan verder dan alleen het ontwerpen van mooie en functionele websites,
                                mobiele applicaties of webapplicaties.
                                We bieden ook expertise in het aantrekken van bezoekers en het stimuleren van extra
                                omzet.
                            </p>
                            <div className="mt-8 inline-flex">
                                <Link to={"/diensten"}>
                                    <Button color="primary" onClick={(e) => {
                                        ReportClick("Bekijk ons aanbod via Contact");
                                    }}>Bekijk ons aanbod</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={""}>
                <hr className="my-6 border-primary"/>
            </div>

            <section>
                <div className={""}>
                    <div
                        className="grid lg:grid-cols-2 gap-5 lg:gap-0 py-8 lg:py-20 mb-5 items-center justify-center justify-items-center">
                        <div className="px-4 mr-5 items-center justify-center justify-items-center">
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                OVERWEEG JE OM EEN EIGEN BEDRIJF TE STARTEN?
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                                Het opbouwen van zichtbaarheid voor je bedrijf is een cruciale factor bij het starten
                                ervan.
                                Denk hierbij aan de gewenste uitstraling, je doelgroep en hoe mensen je bedrijf zullen
                                herkennen.
                                Daarom bieden wij starters graag ondersteuning bij het creëren van een professionele
                                uitstraling
                                voor hun bedrijf.
                                Naast onze expertise in webdesign in Aalst, helpen we ook bij het ontwerpen van logo's,
                                visitekaartjes en het ontwikkelen van een complete huisstijl.

                                Wil je meer informatie? Vul dan ons contactformulier in, zodat ik je persoonlijk kan
                                contacteren.
                            </p>
                        </div>

                        <div className="flex items-center justify-center justify-items-center">
                            <div
                                className="btn btn-primary shadow-2xl rounded-full h-[172px] w-[172px]"
                                onClick={() => {
                                    ReportClick("Contacteer Ons via in-page button");
                                    ScrollToSectionFast("contactsection");
                                }}><ArrowCircleUpRoundedIcon sx={{fontSize: 170}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}