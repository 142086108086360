import {useEffect, useState} from "react";
import {CategoryTiles, CatergoryEdit} from "./categories";
import {EstoreNavigation} from "./estorenavigation";
import {InventoryTiles, ItemEdit} from "./inventory";

export default function StoreContainer(props) {
    const {token, user} = props;
    const [categories, setCategories] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [openView, setOpenView] = useState("Categories");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [openSetting, setOpenSetting] = useState("General");
    const [loadingCats, setLoadingCats] = useState(false);
    const [loadingInv, setLoadingInv] = useState(false);

    function ActiveItem(){
        switch(openView){
            case "Categories":
                return selectedCategory ? selectedCategory.displayname : "No Selection";
            case "Inventory":
                return selectedItem ? selectedItem.displayname : "No Selection";
            case "Purchases":
                return selectedPurchase ? selectedPurchase.displayname : "No Selection";
            case "Settings":
                return openSetting;
        }
    }

    function GetCats() {
        setLoadingCats(true);
        /*
        socket.emit("esgetcats", token, (response) => {
            if (response.status === "success") {
                setCategories(response.cats);
                setLoadingCats(false);
            } else {
                setCategories([]);
                setLoadingCats(false);
            }
        });

         */
    }

    function GetInventory() {
        setLoadingInv(true);
        /*
        socket.emit("esgetinventory", token, (response) => {
            if (response.status === "success") {
                setInventory(response.inventory);
                setLoadingInv(false);
            } else {
                setInventory([]);
                setLoadingInv(false);
            }
        });

         */
    }

    useEffect(() => {
        GetCats();
        GetInventory();
    }, []);

    return (
        <div className={"flex flex-col h-full w-full"}>
            <div className={"flex flex-row w-full items-center justify-between h-14 bg-base-200"}>
                <EstoreNavigation openView={openView} setOpenView={setOpenView}/>
                <div className={"flex flex-col w-1/5 h-full justify-center items-center text-lg font-bold"}>
                    {openView}
                </div>
                <div className={"flex flex-col w-1/5 h-full justify-center items-center text-lg font-bold"}>

                </div>
            </div>
            <div className={"flex flex-row w-full items-center justify-between h-14"}>
                <div className={"flex flex-row w-4/5 items-center justify-between h-full bg-base-100 border-t border-r rounded-tr-xl border-base-300"}>
                    <div></div>
                    <div className={"flex flex-row w-1/5 justify-end items-center px-4"}>Test</div>
                </div>
                <div className={"flex flex-col w-1/5 h-full justify-center items-center text-lg font-bold"}>
                    <div className={"flex flex-row w-[85%] h-full bg-base-100 border-t border-r border-l rounded-t-xl border-base-300 justify-center items-center"}>
                        {ActiveItem()}
                    </div>
                </div>
            </div>
            <div className={"flex flex-row w-full items-center justify-center h-full"}>
                <div
                    className={"flex flex-col w-4/5 h-full bg-base-300 border-r border-base-100"}>
                    {openView === "Categories" && <CategoryTiles inventory={inventory} GetCats={GetCats} token={token} user={user} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} categories={categories}/>}
                    {openView === "Inventory" && <InventoryTiles inventory={inventory} selectedItem={selectedItem} setSelectedItem={setSelectedItem} token={token} user={user} GetInventory={GetInventory} categories={categories} /> }
                </div>

                <div className={"flex flex-col w-1/5 h-full bg-base-200 justify-start items-center gap-2"}>
                    {openView === "Categories" && <CatergoryEdit inventory={inventory} GetCats={GetCats} token={token} user={user} categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>}
                    {openView === "Inventory" && <ItemEdit inventory={inventory} selectedItem={selectedItem} setSelectedItem={setSelectedItem} token={token} user={user} GetInventory={GetInventory} categories={categories} /> }
                </div>
            </div>
        </div>
    )
}