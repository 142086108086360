import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function DeKrachtVanEenWebsiteOpMaat(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> DE KRACHT VAN EEN WEBSITE OP MAAT </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Een website op maat gaat verder dan alleen esthetiek;
                        het is een uitbreiding van uw merk en een essentieel hulpmiddel voor bedrijfsgroei.
                        Een nieuwe professionele website laten maken door een <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("gespecialiseerd_webdesignbureau");
                        }}>
                        gespecialiseerd webdesignbureau</a></Link> zoals het
                        onze betekent dat u kunt rekenen op innovatieve oplossingen die uw verwachtingen overtreffen.
                        Professioneel webdesign en website development zijn de fundamenten waarop wij bouwen,
                        waarbij we nauw samenwerken met u om een website te laten bouwen die uw bedrijfsstrategie ondersteunt.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/ae9169d6-5080-480e-aac2-cb017ee49080/0_1.png"}
                    />
                </div>
            </div>
        </div>
    )
}