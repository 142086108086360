import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {useEffect, useState} from "react";

export function TicketBlock(props) {
    const {tickets} = props;

    return (
        <div className="flex flex-col h-[500px] w-[500px] bg-base-100 rounded-2xl border-base-200 border">
            <div className="flex flex-row h-10 items-center justify-center w-full gap-4 p-2">
                Open Tickets
            </div>
            <div className={"flex flex-col w-full items-center justify-center h-full p-5"}>
                {tickets && tickets.map((ticket) => (
                    <div
                        className={"flex flex-row w-full justify-center items-center h-14 border-base-300 text-white text-sm bg-base-200"}
                        key={ticket._id}>
                        <p>{ticket._id + "  -  " + ticket.participants[0]}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export function AccountBlock(props) {
    const {users,setOpenTab, loadingUsers} = props;
    return (
        <div
            className="flex flex-col h-[500px] w-[500px] bg-base-100 rounded-2xl border-base-200 border overflow-hidden">
            <div className="flex flex-row h-10 items-center justify-center w-full gap-4 p-2 cursor-pointer hover:text-primary"
                 onClick={() => {
                     setOpenTab("accounts");
                 }}>
                Registered Accounts
            </div>
            <div className={"flex flex-col w-full items-center justify-start h-full overflow-hidden p-5"}>
                <div
                    className={"flex flex-col w-full items-center justify-start h-full overflow-y-auto overflow-x-hidden"}>
                    {loadingUsers ?
                        <div className={"flex flex-col h-auto w-full justify-center items-center gap-1"}>
                            Loading Accounts
                        </div>
                        : <div className={"flex flex-col h-auto w-full gap-1"}>
                        {users && users.map((usr) => (
                            <div
                                className={"flex flex-row w-full justify-center items-center h-14 border-base-300 text-white text-sm " + (usr.admin ? "bg-base-300" : "bg-base-200")}
                                key={usr._id}>
                                {usr.admin && <AdminPanelSettingsIcon />}
                                <p>{usr.accountname + " - " + usr.email}</p>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
            <div className="flex flex-row h-10 items-center justify-center w-full gap-4 p-2 text-sm">
                {"Currently there are " + users.length + " registered accounts."}
            </div>
        </div>
    )
}

export function SessionsBlock(props) {
    const {user, token} = props;
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        GetSessions();
    }, [])

    function GetSessions() {

    }

    return (
        <div
            className="flex flex-col h-[500px] w-[500px] bg-base-100 rounded-2xl border-base-200 border overflow-hidden">
            <div className="flex flex-row h-10 items-center justify-center w-full gap-4 p-2">
                Active Sessions
            </div>
            <div className={"flex flex-col w-full items-center justify-start h-full overflow-y-auto overflow-x-hidden p-5"}>
                <div className={"flex flex-col h-auto w-full gap-1"}>
                    {sessions && sessions.map((session) => (
                        <div className={"flex flex-col w-full justify-center items-center h-14 bg-base-200 border-base-300 text-white text-sm"}
                             key={session.token}>
                            <p>{session.token + " created on " + session.created}</p>
                            <p>{"Belongs to " + session.user.email}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}