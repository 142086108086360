import React, {useEffect, useState} from "react";
import ArticleView from "./articleview";
import ArticleEdit from "./articleEdit";
import {useAppContext} from "../context/appContext";

export default function Article(props) {
    const {art} = props;
    const {session, username, isAdmin} = useAppContext();

    const [editing, setEditing] = useState(false);
    if (!session || !isAdmin) return (
        <ArticleView art={art} setEditing={setEditing}/>);
    else return (
        <>
            {!editing && <ArticleView art={art} setEditing={setEditing} />}
            {editing && <ArticleEdit art={art} setEditing={setEditing} />}
        </>)
}