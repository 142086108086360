
import React from "react";
import SendIcon from '@mui/icons-material/Send';
import OnzePartners from "../components/OnzePartners";
import {Button} from "react-daisyui";
import {ReportClick} from "../utils/gtag";
import BreadCrumb from "../components/BreadCrumb";
import {useAppContext} from "../context/appContext";
import { Link } from "react-router-dom";

export default function AboutUs(props){
    return (
        <div>
        <div className={"container mt-16"}>
            {/*<EditableBlock user={user} blockid={"about"}/>*/}
            <section className="flex flex-col w-full">
                <div className="mx-auto px-4 items-center justify-items-start">
                    <div className="flex flex-wrap items-center py-8 lg:py-20">
                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                            {/*<div
                                className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                                <i className="fas fa-user-friends text-xl"></i>
                            </div>*/}
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Over ons
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                                Tech Mania is een jong en dynamisch bedrijf gevestigd in Aalst, opgericht door Gert
                                Coppens en Michael Peters.
                                Wij zijn gespecialiseerd in softwareontwikkeling en game development,
                                met een focus op zowel individuele klanten als bedrijven in verschillende sectoren.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                                Als medeoprichters streven we naar een voortreffelijke dienstverlening en
                                hebben we als doel om andere bedrijven op freelance basis regelmatig te ondersteunen.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                                Gert brengt zijn passie en ervaring voor softwareontwikkeling en technologie in,
                                waarbij hij uitblinkt in het coördineren van projecten,
                                het ontwerpen van producten, het opsporen van fouten en het optimaliseren van
                                programma's voor de gebruiker.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                                Michael, aan de andere kant, deelt dezelfde passie en ervaring voor softwareontwikkeling
                                en
                                excelleert voornamelijk in programmeren. Hij beschikt over een diepgaande kennis van het
                                technische aspect,
                                inclusief netcode, en heeft een opmerkelijk talent om zich snel de nieuwste technieken
                                eigen te maken.
                            </p>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                De ontkieming
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                                In 2023 hebben Gert Coppens en Michael Tech Mania opgericht met als doel zelfstandigen
                                en kleine bedrijven te ondersteunen op het gebied van IT.
                                Dankzij hun gezamenlijke expertise kunnen zij op maat gemaakte oplossingen bieden,
                                waarbij Gert zich voornamelijk richt op het ontwerp en de kwaliteitsborging,
                                terwijl Michael zich toelegt op het technische aspect.
                            </p>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                De groei
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                                Tech Mania streeft ernaar een betrouwbare partner te worden voor digitale oplossingen,
                                waarbij de unieke samenwerking tussen Gert en Michael een solide basis zal
                                vormen voor toekomstige succesvolle projecten.</p>
                            <div className="h-10">
                            </div>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                                Gert en Michael </p>
                            <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                                Tech Mania </p>
                        </div>

                        <div className="flex artboard phone-6 px-4 mr-auto ml-auto">
                            <div
                                className="flex flex-col break-words w-full mb-6 shadow-lg rounded-lg">
                                <img
                                    alt="..."
                                    src="https://cdn.midjourney.com/fd4805f3-f209-45a1-a95f-3a2da7866367/0_0_2048_N.webp?method=width&qst=6"
                                    className="object-cover w-full h-screen rounded-lg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-primary"/>
                <div className="w-full md:w-8/12 px-4 mr-auto ml-auto py-8 lg:py-20">

                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                        Meer weten over ons?
                    </h3>
                    <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                        Meer weten over ons en wat we voor jouw bedrijf kunnen betekenen? Neem gerust contact
                        met ons op voor een kennismakingsgesprek. We kijken ernaar uit om jouw digitale ambities
                        te realiseren. </p>
                    <div className={"mt-10"}>
                        <div className="mt-8 inline-flex">
                            <Link to={"/diensten"}>
                            <Button color="primary" onClick={(e) => {
                                ReportClick("Bekijk ons aanbod via Info");
                            }} endIcon={<SendIcon/>}>Bekijk ons aanbod</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-primary"/>
                {/*<OnzePartners/>*/}
            </section>
        </div>
            <OnzePartners/>
        </div>
    )
}