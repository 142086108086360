import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function MaatwerkVanUwWebdesignbureau(props) {


    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> MAATWERK VAN UW WEBDESIGNBUREAU </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Als uw webdesignbureau begrijpen we dat elke onderneming uniek is.
                        Daarom bouwen wij uw <Link to={"/website-op-maat"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("website_op_maat");}}>
                        website op maat.</a></Link> Ons team zorgt voor een perfect webdesign, waarbij functionaliteit,
                        esthetiek en
                        gebruiksvriendelijkheid hand in hand gaan.
                        Daarnaast zijn we niet alleen uw websitebouwer; als<Link to={"/appontwikkelaar"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("ervaren_appontwikkelaar");
                        }}> ervaren appontwikkelaar</a></Link> ontwikkelen wij ook apps en
                        webapplicaties die uw online aanwezigheid versterken.
                        Elke webontwikkelaar in ons team draagt bij aan deze persoonlijke aanpak.
                        Als toegewijde webdeveloper streven we ernaar om oplossingen te creëren die niet alleen vandaag
                        de dag indruk maken,
                        maar ook toekomstbestendig zijn.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/b43a2395-d072-4ea8-a35d-161d75758c98/0_1.png"}
                    />
                </div>
            </div>
        </div>
    )
}