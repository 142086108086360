import React from "react";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";
import {GetRoutePage} from "../../utils/routes";

export default function OnzeProgrammeursZijnDeSleutelTotEenFeilozeEnModerneWebsite(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[514px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> ONZE PROGRAMMEURS ZIJN DE SLEUTEL TOT EEN FEILOZE EN MODERNE WEBSITE </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Een goede programmeur zorgt ervoor dat uw website perfect functioneert.
                        Wij garanderen dat uw digitale platform zonder problemen werkt.
                        Daarnaast zorgt  <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("ons_webdesignbureau");}}>
                        ons webdesignbureau</a></Link> ervoor dat u niet alleen een functionele,
                        maar ook een gebruiksvriendelijke en professioneel ontworpen website krijgt.
                        Uw  <Link to={"/website-op-maat"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("website_op_maat");}}>
                            website op maat</a></Link> wordt ontwikkeld met de nieuwste technologieën en trends
                        in het achterhoofd,
                        zodat deze niet alleen vandaag de dag indruk maakt, maar ook in de toekomst relevant blijft.
                        Onze programmeurs zijn ook bedreven in  <Link to={"/appontwikkelaar"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("appontwikkeling");}}>
                        appontwikkeling</a></Link>,
                        wat betekent dat we naast websites ook mobiele apps kunnen creëren die uw bedrijf verder kunnen
                        helpen groeien.
                        Of het nu gaat om e-commerce platforms, interactieve klantportalen of geïntegreerde
                        bedrijfssystemen,
                        onze programmeurs hebben de expertise om complexe projecten van begin tot eind te beheren.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-auto bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:h-[514px] xl:top-[1294px] left-[1206px] rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/5e4146ae-189e-45cd-829a-61d86257a72a/0_0.png"}
                    />
                </div>
            </div>
        </div>
    )
}