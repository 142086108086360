
import React from "react";
import {useAppContext} from "../context/appContext";

export default function ImageBanner2(props) {

    return (
        <section className="container flex w-full h-auto py-8 lg:py-5 lg:mb-10">
            <div className="flex mx-auto -mt-1 items-center justify-center">
                <div className="flex flex-wrap">

                    <div className="lg:pt-12 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                            <img
                                alt="..."
                                src="https://cdn.midjourney.com/7935b1aa-74d8-47ce-97af-d735bd6b601c/0_0.png"
                                className="w-full align-middle rounded-lg hover:scale-105"
                            />
                        </div>
                    </div>

                    <div className="lg:pt-12 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                            <img
                                alt="..."
                                src="https://cdn.midjourney.com/89117e37-d322-4ffe-a651-189d8d462615/0_1.png"
                                className="w-full align-middle rounded-lg hover:scale-105"
                            />
                        </div>
                    </div>

                    <div className="lg:pt-12 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                            <img
                                alt="..."
                                src="https://cdn.midjourney.com/3f50ed07-3aa7-4088-8dca-7fcd0518d01f/0_3.png"
                                className="w-full align-middle rounded-lg hover:scale-105"
                            />
                        </div>
                    </div>

                    <div className="lg:pt-12 pt-6 w-1/2 md:w-3/12 px-4 text-center">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full lg:w-full mb-6 shadow-lg rounded-s bg-white-600">
                            <img
                                alt="..."
                                src="https://cdn.midjourney.com/58eeee82-9935-41d1-8334-e76f4c57c1b1/0_3.png"
                                className="w-full align-middle rounded-lg hover:scale-105"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
