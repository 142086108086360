import {tabs} from "./dashboard";

export default function SideBar(props) {
    const {sidebarOpen, setSidebarOpen, openTab, setOpenTab, users} = props;
    return (
        <div className="flex fixed lg:relative flex-col h-auto lg:h-full self-end text-xs lg:text-sm w-1/2 lg:w-1/6 justify-center items-center overflow-hidden bg-base-100 py-2 border-base-200 border-b border-l lg:border-b-0 lg:border-l-0 ">
            <Breadcrumb openTab={openTab} setOpenTab={setOpenTab}/>
            <div className="flex lg:hidden flex-col w-full h-auto bg-base-100 justify-start items-center uppercase">
                {tabs.map((tab, index) => (
                    <div key={tab.id}
                        className={"btn btn-ghost w-auto h-10 p-2 " + (openTab === tab.id ? " text-primary" : " text-secondary")}
                        onClick={() => {
                            setOpenTab(tab.id);
                            setSidebarOpen(false);
                        }}>
                        {tab.display}
                    </div>
                ))}
            </div>
            <div className={"hidden lg:flex flex-col w-full h-full overflow-y-auto overflow-x-hidden"}>

            </div>
        </div>
    )
}

function Breadcrumb(props) {
    const {openTab, setOpenTab} = props;
    let tab = openTab;
    if (openTab === "dash") tab = "Dashboard";
    if (openTab === "tickets") tab = "Tickets";
    if (openTab === "accounts") tab = "Accounts";
    return (
        <div className={"flex flex-row w-full justify-start items-center h-12 px-4 gap-2"}>
            <div className={"hover:text-primary"} onClick={() => {
                window.open("https://tech-mania.be", "_self");
            }}>Home
            </div>
            <p>/</p>
            <p className={"hover:text-primary"} onClick={() => { setOpenTab("dash"); }}>AdminPanel</p>
            <p>/</p>
            <p>{tab}</p>
        </div>
    )
}