import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {Button} from "react-daisyui";
import {ReportClick} from "../../utils/gtag";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";

export default function Appmakenvanwebsite2(props) {

    return (
        <div className={"pb-8 lg:pb-10"}>
            <div
                className="flex flex-col lg:w-full xl:flex-row xl:w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full xl:w-[615px] lg:mb-16 mb-10 xl:mb-0"}>
                    <div>
                        <h1 className="xl:w-[459px] xl:h-[146px] text-4xl font-black lg:text-6xl lg:leading-none mt-2">
                            <span className="text-primary"> APP MAKEN VAN WEBSITE </span>
                        </h1>
                    </div>
                    <div>
                        <p className="mt-8 text-lg xl:w-[615px] xl:h-[224px]">
                            Het concept om een app te maken van uw website wint snel aan populariteit, en om een goede reden.
                            Bij Tech Mania in Aalst zetten we ons zo in om uw website nog meer op de kaart te zetten.
                            Door een app te maken van uw website transformeert uw online aanwezigheid in een meer toegankelijke en gebruikersvriendelijke mobiele applicatie.
                        </p>
                    </div>
                </div>
                <div
                    className={"flex flex-col xl:w-[394px] h-auto lg:h-[318px] xl:h-[418px] border-t border-b border-primary border-1 justify-center items-center"}>
                    <div className="flex flex-col lg:w-5/6 px-4 mr-auto ml-auto py-8">
                        <h3 className="text-1xl mb-2 font-bold">
                            MEER WETEN OVER ONS?
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                            Meer weten over ons en wat we voor jouw bedrijf kunnen betekenen? Neem gerust contact
                            met ons op voor een kennismakingsgesprek. We kijken ernaar uit om jouw digitale ambities
                            te realiseren. </p>
                        <div className={"mt-6"}>
                            <a className="">
                                <Link to={"/diensten"}>
                                    <Button color="primary" onClick={(e) => {
                                        ReportClick("bekijk_ons_aanbod_op_webdesignbureau-aalst");
                                    }} endIcon={<SendIcon/>}>Bekijk ons aanbod</Button>
                                </Link>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}