export function EstoreNavigation(props) {
    const {openView, setOpenView} = props;
    return (
        <div className={"flex flex-row w-1/5 h-full justify-center items-center text-lg font-bold gap-2"}>
            <div className={"btn btn-ghost btn-sm " + (openView === "Categories" ? " text-primary" : " text-secondary")}
                 onClick={() => {
                     setOpenView("Categories");
                 }}>
                Categories
            </div>
            <div className={"btn btn-ghost btn-sm " + (openView === "Inventory" ? " text-primary" : " text-secondary")}
                 onClick={() => {
                     setOpenView("Inventory");
                 }}>
                Inventory
            </div>
            <div className={"btn btn-ghost btn-sm " + (openView === "Purchases" ? " text-primary" : " text-secondary")}
                 onClick={() => {
                     setOpenView("Purchases");
                 }}>
                Purchases
            </div>
            <div className={"btn btn-ghost btn-sm " + (openView === "Settings" ? " text-primary" : " text-secondary")}
                 onClick={() => {
                     setOpenView("Settings");
                 }}>
                Settings
            </div>
        </div>
    )
}