import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function DeImpactVanEenAppontwikkelaarOpUwBedrijf(props) {
    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> DE IMPACT VAN EEN APPONTWIKKELAAR OP UW BEDRIJF</span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Een app kan een aanzienlijke meerwaarde bieden voor uw bedrijf,
                        vooral wanneer deze is ontwikkeld met uw specifieke doelgroep in gedachten.
                        Onze appontwikkelaars duiken diep in uw bedrijfsmodel om ervoor te zorgen dat uw app niet alleen functioneel is,
                        maar ook de juiste mensen aanspreekt. Als <Link to={"/webdesignbureau-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("toonaangevend_webdesignbureau");}}>
                        toonaangevend webdesignbureau</a></Link> zorgen we dat uw app mooi én makkelijk
                        te gebruiken is. <Link to={"/programmeur-aalst"}><a
                            className="link-info link cursor-pointer text-primary font-semibold"
                            onClick={() => { ReportClick("onze_programmeurs");}}>
                            Onze programmeurs</a></Link>  werken nauwgezet aan de technische perfectie van uw app.
                        Wanneer u ons
                        inschakelt om een <Link to={"/mobiele-app-laten-maken"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {  ReportClick("mobiele_app_te_maken");}}>
                        mobiele app te maken</a></Link>,
                        zetten wij functionaliteit en gebruikerservaring centraal bij de applicatieontwikkeling.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/b43a2395-d072-4ea8-a35d-161d75758c98/0_1.png"}
                    />
                </div>
            </div>
        </div>
    )
}