import googleImage from "../assets/logo/google.svg";
import stripeImage from "../assets/logo/stripe.svg";
import grizzlyImage from "../assets/logo/grizzly.png";
import vismaImage from "../assets/logo/visma.svg";
import klarnaImage from "../assets/logo/klarna.svg";
import {ReportOutgoingClick} from "../utils/gtag";
import {useAppContext} from "../context/appContext";
export default function OnzePartners(props) {

    return (
        <section className="py-12 lg:py-20 bg-gradient-to-r from-cyan-500 to-blue-500" id="partners">
            <div className="">

                {/*<h2 className="-mt-10 text-center text-2xl font-semibold">
                    PARTNERS
                </h2>*/}

                <div
                    className="mt-6 mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-center content-center justify-center gap-8">
                    <img
                        className="mx-auto h-8 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 hover:scale-110"
                        src={googleImage}
                        alt="google logo"
                        onClick={() => {
                            window.open("https://www.google.com/", "_blank");
                            ReportOutgoingClick("googleLogo", "https://www.google.com/");
                        }}
                    />
                    <img
                        className="mx-auto h-12 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 hover:scale-110"
                        src={stripeImage}
                        alt="stripe logo"
                        onClick={() => {
                            window.open("https://stripe.com/", "_blank");
                            ReportOutgoingClick("stripeLogo", "https://stripe.com/en-be");
                        }}
                    />
                    <img onClick={() =>  {
                        window.open("https://www.klarna.be/", "_blank");
                        ReportOutgoingClick("klarnaLogo", "https://www.klarna.be/");
                    }}
                         className="mx-auto h-10 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 md:inline hover:scale-110"
                         src={klarnaImage}
                         alt="klarna logo"
                    />
                    <img onClick={() => {
                        window.open("https://www.grizzlymarketing.be/", "_blank");
                        ReportOutgoingClick("grizzlyLogo", "https://www.grizzlymarketing.be/");
                    }}
                         className="mx-auto mt-2 lg:mt-0 h-20 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 sm:inline hover:scale-110"
                         src={grizzlyImage}
                         alt="grizzly logo"
                    />

                    <img onClick={() => {
                        window.open("https://www.visma.be/", "_blank");
                        ReportOutgoingClick("paypalButton", "https://www.visma.be/");
                    }}
                         className="mx-auto h-6 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 sm:inline hover:scale-110"
                         src={vismaImage}
                         alt="visma logo"
                    />
                </div>
            </div>
        </section>
    );
};
