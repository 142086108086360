import {useEffect, useState} from "react";
import {notify} from "../components/notifications";
import SendIcon from "@mui/icons-material/Send";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {ArrowLeftIcon, ArrowRightIcon} from "lucide-react";
import {getDutchDateTimeFromStamp} from "../utils/general";

export function TicketPage(props) {
    const {tickets, token, GetTickets, user, loadingTickets, unreadtickets} = props;
    const [openTicket, setOpenTicket] = useState(null);
    const [responseText, setResponseText] = useState("");
    const [ticketsOpen, setTicketsOpen] = useState(false);


    function SendMessage(){
        /*
        socket.emit("ticketmessage", {token: token, ticket: openTicket, message: responseText}, (response) => {
            if (response.status === "success") {
                GetTickets();
                setOpenTicket(response.ticket);
            } else {
                notify({
                    status: "failed",
                    msg: "Failed to respond."
                });
            }
        });
        setResponseText("");

         */
    }

    return (
        <div className={"flex flex-col lg:flex-row w-full items-center justify-center h-full overflow-hidden"}>
            <div className={"flex flex-col w-full h-full items-center justify-center text-sm"}>
                <div className={"flex flex-col w-full h-full"}>
                    <div
                        className="flex flex-row h-14 items-center justify-between w-full py-2 px-2 border-base-300 border-b">
                        <div className={"flex lg:hidden btn btn-sm btn-secondary"} onClick={() => {
                            setTicketsOpen(!ticketsOpen);
                        }}>{"Tickets [" + tickets.length + "]"}</div>
                        <div className={"hidden lg:flex btn btn-sm btn-secondary"} onClick={() => {
                            setTicketsOpen(!ticketsOpen);
                        }}>{ticketsOpen ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
                        </div>
                        {openTicket && <p>{"Support ticket with " + openTicket.participants[0]}</p>}
                    </div>
                    {ticketsOpen &&
                        <div
                            className={"fixed lg:hidden flex flex-col w-1/2 h-full overflow-y-auto overflow-x-hidden bg-base-100 border-base-200 border"}>
                            <div className={"flex flex-row h-12 w-full justify-start items-center p-2"}><div className={"btn btn-sm btn-primary"} onClick={()=>{setTicketsOpen(false); }}>Close</div></div>

                            {loadingTickets ?
                                <div className={"flex flex-col w-full items-center justify-center h-auto gap-3 p-2 lg:p-5"}>
                                    Loading Tickets..
                                </div>
                                :
                            <div className={"flex flex-col w-full items-center justify-start h-auto gap-3 p-2 lg:p-5"}>
                                {tickets && tickets.map((ticket) => (
                                    <div
                                        className={"flex flex-row w-full cursor-pointer justify-center items-center rounded-lg h-14 border-base-300 text-white text-sm " + (openTicket === ticket ? " bg-secondary" : " bg-base-300")}
                                        key={ticket._id} onClick={() => {
                                        setOpenTicket(ticket);
                                        setTicketsOpen(false);
                                    }}>
                                        <p className={"flex flex-row w-full h-full text-center justify-center items-center"}>
                                            {ticket.participants[0]}
                                            <div className="badge">0</div>
                                        </p>
                                    </div>
                                ))}
                            </div>}
                        </div>}
                    <div className={"flex flex-row h-full w-full overflow-hidden"}>
                        {ticketsOpen &&
                            <div className={"hidden lg:flex flex-col w-1/5 h-full overflow-y-auto overflow-x-hidden bg-base-100 border-base-200 border"}>
                                {loadingTickets ?
                                    <div className={"flex flex-col w-full items-center justify-center h-auto gap-3 p-2 lg:p-5"}>
                                        Loading Tickets..
                                    </div>
                                    :
                                <div className={"flex flex-col w-full items-center justify-center h-auto gap-3 p-2 lg:p-5"}>
                                    {tickets && tickets.map((ticket) => (
                                        <div
                                            className={"flex flex-row w-full cursor-pointer justify-center items-center rounded-lg h-14 border-base-300 text-white text-sm " + (openTicket === ticket ? " bg-secondary" : " bg-base-300")}
                                            key={ticket._id} onClick={() => {
                                            setOpenTicket(ticket);
                                        }}>
                                            <p className={"flex flex-row w-full h-full text-center justify-center items-center"}>{ticket.participants[0]}</p>

                                        </div>
                                    ))}
                                </div>}
                            </div>}
                        {openTicket && <TicketView loadingTickets={loadingTickets} openTicket={openTicket} ticketsOpen={ticketsOpen} user={user} SendMessage={SendMessage} responseText={responseText} setResponseText={setResponseText}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

function TicketView(props){
    const {ticketsOpen, openTicket, user, responseText, setResponseText, SendMessage, loadingTickets} = props;
    const [scrollPos, setScrollPos] = useState(0);
    const [notBottom, setNotBottom] = useState(false);

    useEffect(()=> {
        if(openTicket !== null && openTicket.messages.length > 5) ScrollToBottom();
    }, [openTicket])

    useEffect(() => {
        const tc = document.querySelector("#ticketcontainer");
        const te = tc.querySelector("#ticketsend");
        if(openTicket !== null) {
            if(tc.scrollTop < te.scrollHeight) setNotBottom(true);
            else setNotBottom(false);
        }
    }, [scrollPos]);

    function ScrollToBottom(){
        const tc = document.querySelector("#ticketcontainer");
        tc.scrollTo({
            top: (tc.scrollHeight),
            behavior: "smooth",
        });
    }

    return (
        <div className={"flex flex-col items-center justify-start h-full text-sm lg:text-md overflow-hidden " + (ticketsOpen ? "w-full lg:w-4/5" : "w-full")}>
            <div className={"flex flex-col h-[80%] w-full"}>
            <div id="ticketcontainer" onScroll={(e) => {
                setScrollPos(e.currentTarget.scrollTop);
            }} className={"flex flex-col items-start justify-start h-full w-full overflow-y-auto overflow-x-hidden"}>
                <div className={"flex flex-col items-start gap-2 justify-start h-auto w-full p-4"}>
                    {openTicket.messages.map((message, ind) => (
                        <div id={"msg" + ind} key={"msg" + ind}
                             className={"flex flex-col w-full h-auto p-2 lg:p-4 rounded-xl " + (message.sender === user.email ? " bg-secondary" : " bg-base-300")}>
                            <div
                                className={"flex-row w-full h-auto text-end justify-between items-end text-xs px-2"}>
                                <div className={"flex flex-row gap-3"}>
                                    <p className={"text-lg font-bold"}>{message.staffresponse &&
                                        <AdminPanelSettingsIcon/>}{message.displayname + " <" + message.sender + ">"}</p>
                                    <p className={"flex text-xs items-center"}>{" at " + getDutchDateTimeFromStamp(message.date)}</p>
                                </div>
                                <div
                                    className={"flex flex-row h-full w-[200px] justify-start items-start italic"}>
                                    {(message.staffresponse ? "Staff Response " : "Customer Response ")}
                                </div>
                            </div>
                            <div className={"flex-wrap h-auto w-full px-2 py-4"}>
                                {message.message}
                            </div>
                        </div>
                    ))}
                    <div id={"ticketsend"} className={"h-1 w-1"}></div>
                </div>
            </div>
            </div>
            <div className={"flex flex-col items-start justify-start h-[20%] w-full pb-12 px-4"}>
                <div className={"flex flex-row h-14 w-full justify-end p-2 border-base-300 border-t"}>
                    <div className={(notBottom ? "flex" : "hidden") + " btn btn-xs btn-ghost"}
                         onClick={() => {
                             ScrollToBottom();
                         }}>Jump to Latest
                    </div>
                </div>
                <div className={"flex w-full h-full items-start justify-start flex-row p-2"}>
                                            <textarea value={responseText}
                                                      onChange={(e) => {
                                                          setResponseText(e.currentTarget.value)
                                                      }}
                                                      rows={2}
                                                      className={"textareatickets flex bg-base-content rounded-xl text-black w-full h-full"}/>
                    <div className={"btn btn-secondary flex flex-row w-14 h-full mx-2"} onClick={() => {
                        SendMessage();
                    }}><SendIcon/></div>
                </div>
            </div>
        </div>
    )
}