import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function UwVisieOnsVakmanschap(props) {

    return (
        <div className={"py-8 lg:py-10"}>
            <div className="flex flex-col xl:flex-row w-full h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col xl:w-[615px] xl:h-[354px] bg-base-100 mb-10 lg:mb-20 xl:mb-0"}>
                    <h2 className="text-2xl lg:text-4xl font-bold">
                        <span className=""> UW VISIE, ONS VAKMANSCHAP </span>
                    </h2>
                    <p className="mt-8 text-lg">
                        Bij het ontwikkelen van uw website op maat, combineren we voor uw website development
                        creativiteit met technische vaardigheden.
                        Het proces van een nieuwe professionele website laten maken is collaboratief. <Link to={"/programmeur-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("onze_programmeurs");
                        }}>
                        Onze programmeurs</a></Link> werken aan de kernfunctionaliteiten om een robuuste en
                        veilige website te garanderen.
                        In een aparte ontwikkelingsfase zorgen onze <Link to={"/back-end-developer-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("back-end_developers");
                        }}>
                        back-end developers</a></Link> ervoor dat alle systemen
                        naadloos samenwerken,
                        terwijl ons team van designers ervoor zorgt dat het professioneel webdesign uw merk versterkt en
                        aantrekkelijk is voor uw doelgroep.
                    </p>
                </div>
                <div className={"flex flex-col xl:w-[394px] xl:h-[354px] bg-base-100 overflow-hidden rounded-lg"}>
                    <img
                        alt=""
                        id=""
                        className="object-cover xl:w-[394px] xl:h-[354px] top-[840px] left-[1206px] align-middle rounded-lg hover:scale-105"
                        src={"https://cdn.midjourney.com/b43a2395-d072-4ea8-a35d-161d75758c98/0_1.png"}
                    />
                </div>
            </div>
        </div>
    )
}