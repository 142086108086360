import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function StartVandaagNogMetUwOnlineTransformatie(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> START VANDAAG NOG MET UW ONLINE TRANSFORMATIE </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Bent u klaar om een nieuwe professionele website te laten maken en uw digitale voetafdruk te vergroten?
                        Als <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => { ReportClick("uw_toegewijde_it-partner");}}>
                        uw toegewijde IT-partner</a></Link> zijn we hier om u door het hele proces te
                        leiden, van idee tot lancering.
                        Laat ons uw droom van een perfecte website op maat realiseren, ondersteund door ons vakmanschap
                        in webdesign en development.
                    </p>
                </div>
            </div>
        </div>
    )
}