import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function StartUwDigitaleToekomstHier(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> START UW DIGITALE TOEKOMST HIER </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Wacht niet langer en zet vandaag nog de stap naar digitale innovatie.
                        Als uw <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("betrouwbare_it_partner");
                        }}>
                        betrouwbare IT-partner</a></Link> staan we klaar om u te begeleiden bij elke stap van de
                        applicatieontwikkeling.
                        Neem contact met onze appontwikkelaar op en ontdek hoe we samen uw unieke app kunnen realiseren.
                        Laat ons uw ideeën tot leven brengen en samenwerken aan het succes van uw onderneming in het
                        digitale tijdperk.
                    </p>
                </div>
            </div>
        </div>
    )
}