import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function OntdekDeMogelijkhedenVoorUwBedrijf(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> ONTDEK DE MOGELIJKHEDEN VOOR UW BEDRIJF </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Bent u klaar om de volgende stap in digitale evolutie te zetten en een app te maken van uw
                        website?
                        Als <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("uw_IT-partner");
                        }}>
                        uw IT-partner</a></Link> staan wij klaar om deze reis met u te ondernemen.
                        Onze <Link to={"/appontwikkelaar"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("expertise_in_appontwikkeling");
                        }}>
                        expertise in appontwikkeling</a></Link> garandeert een soepele transformatie van uw website naar een
                        functionele en aantrekkelijke mobiele app.
                        Neem vandaag nog contact met ons op en laten we samen de mogelijkheden verkennen om uw bedrijf
                        op te waarderen.
                    </p>
                </div>
            </div>
        </div>
    )
}