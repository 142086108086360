import React from "react";
import {GetRoutePage} from "../../utils/routes";
import {ReportClick} from "../../utils/gtag";
import { Link } from "react-router-dom";

export default function ZetDeStapNaarInnovatie(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> ZET DE STAP NAAR INNOVATIE </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Maak de sprong naar digitale innovatie door een mobiele app te laten maken die uw bedrijf
                        transformeert.
                        Als <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("uw_IT-partner");
                        }}>
                        uw IT-partner</a></Link> begeleiden wij u bij elke stap, van de eerste ideeën tot aan de lancering van
                        de app. <Link to={"/appontwikkelaar"}><a
                            className="link-info link cursor-pointer text-primary font-semibold"
                            onClick={() => {
                                ReportClick("onze_appontwikkelaars");
                            }}>
                            Onze appontwikkelaars</a></Link> zijn experts in de creatie van mobiele apps, afgestemd op uw specifieke
                        bedrijfsbehoeften.
                        Daarom bieden we ontwikkelingsservices aan die het mogelijk maken om een <Link to={"/app-maken-van-website"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("app_van_uw_website_te_maken");
                        }}>
                        app van uw website te maken</a></Link>.
                        Neem vandaag nog contact met ons op om de mogelijkheden te bespreken en uw bedrijf te verrijken
                        met de creatie van een mobiele app.
                    </p>
                </div>
            </div>
        </div>
    )
}