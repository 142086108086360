import {useEffect, useState} from "react";
import {notify} from "../../components/notifications";
import {getDutchDateTime, getDutchDateTimeFromStamp} from "../../utils/general";

export function CategoryTiles(props) {
    const {categories, selectedCategory, setSelectedCategory, user, token, GetCats} = props;
    const [newName, setNewName] = useState("New Name");
    const [newType, setNewType] = useState("Physical");
    const [newDescription, setNewDescription] = useState("New Description");

    function newCategory() {
        let newcat = {};
        newcat.displayname = newName;
        newcat.type = newType;
        newcat.description = newDescription;
        newcat.createdby = user.email;
        newcat.createdat = new Date().getTime();
        /*
        socket.emit("esaddcategory", {newcat: newcat, token: token}, (response) => {
            if (response.status === "success") {
                notify({
                    status: "success",
                    msg: "Category created successfully submitted."
                })
                setNewName("New Name");
                setNewType("Physical");
                setNewDescription("New Description");
                GetCats();
            }
        });

         */
    }

    return (
        <div className={"flex flex-col h-full w-full items-start justify-start overflow-y-auto overflow-x-hidden"}>
            <div className={"flex flex-wrap w-full items-start justify-start h-auto gap-4 p-4"}>
                {categories && categories.map((category, ind) => (
                    <div key={category._id + ind} onClick={() => {
                        setSelectedCategory(category);
                    }}
                         className={"flex flex-col w-44 h-44 cursor-pointer gap-2 items-center justify-center rounded-xl border border-base-100 hover:bg-opacity-80 " + (selectedCategory === category ? "bg-secondary" : "bg-base-200")}>
                        <p className={"text-lg font-bold"}>{category.displayname}</p>
                        <p className={"text-xs italic"}>{category.type}</p>
                        <p className={"text-sm"}>{category.description}</p>
                    </div>
                ))}
                <div className={"flex flex-col w-44 h-44 justify-center items-center  text-white rounded-xl border border-base-100 bg-green-950"}>
                    <div key={"newcat"} className={"flex flex-col w-full h-auto items-center justify-center gap-2 px-2"}>
                            <input className={"input input-xs input-bordered w-full"} type={"text"} value={newName}
                                   onChange={(e) => {
                                       setNewName(e.currentTarget.value);
                                   }}/>
                            <select value={newType} onChange={(e) => {
                                setNewType(e.currentTarget.value);
                            }} className="select select-xs select-bordered w-full">
                                <option>Physical</option>
                                <option>Virtual</option>
                            </select>
                            <textarea className={"textareatickets input input-xs input-bordered w-full h-12 p-2"}
                                      rows={3} value={newDescription} onChange={(e) => {
                                setNewDescription(e.currentTarget.value);
                            }}/>
                        <div className={"btn btn-ghost bg-green-600 btn-xs"} onClick={() => {
                            newCategory();
                        }}>Create New
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CatergoryEdit(props) {
    const {selectedCategory, setSelectedCategory, user, token, GetCats} = props;
    const [tempName, setTempName] = useState("");
    const [tempType, setTempType] = useState("");
    const [tempDescription, setTempDescription] = useState("");

    function DeleteCategory(category) {
        /*
        socket.emit("esdeletecategory", {id: category._id, token: token}, (response) => {
            if (response.status === "success") {
                setSelectedCategory(null);
                GetCats();
                notify({
                    status: "success",
                    msg: "Category successfully deleted."
                })
            } else {
                notify({
                    status: "failed",
                    msg: "Category failed to delete."
                })
            }
        });

         */
    }

    function SubmitEdit() {
        /*
        socket.emit("eseditcat", {
            id: selectedCategory._id,
            token: token,
            changes: {
                displayname: tempName,
                type: tempType,
                description: tempDescription
            }
        }, (response) => {
            if (response.status === "success") {
                GetCats();
                notify({
                    status: "success",
                    msg: "Feed post successfully edited."
                })
            } else {
                notify({
                    status: "failed",
                    msg: "Feed post failed to edit."
                })
            }
        });

         */
    }

    useEffect(() => {
        if (selectedCategory !== null) {
            setTempName(selectedCategory.displayname);
            setTempType(selectedCategory.type);
            setTempDescription(selectedCategory.description);
        }
    }, [selectedCategory])

    if(selectedCategory === null) return (
        <div className={"flex flex-col w-[85%] h-full items-center justify-start bg-base-300 pt-10"}></div>);
    else return (
        <div className={"flex flex-col w-[85%] h-full items-center justify-start bg-base-300 pt-10"}>
            <div className={"flex flex-col w-[85%] h-auto justify-center items-center gap-6"}>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Category Name</p>
                    <input className={"input input-bordered w-full"} type={"text"} value={tempName} onChange={(e) => {
                        setTempName(e.currentTarget.value);
                    }}/>
                </div>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Category Type</p>
                    <select value={tempType} onChange={(e) => {
                        setTempType(e.currentTarget.value);
                    }} className="select select-bordered w-full">
                        <option>Physical</option>
                        <option>Virtual</option>
                    </select>
                </div>
                <div className={"flex flex-col justify-center items-center w-full h-auto gap-2"}>
                    <p className={"font-bold"}>Description</p>
                    <textarea className={"textareatickets input input-bordered w-full h-24 max-h-46 p-2"} rows={3}
                              value={tempDescription} onChange={(e) => {
                        setTempDescription(e.currentTarget.value);
                    }}/>
                </div>
                <div className={"text-xs"}>{selectedCategory._id}</div>
                <div className={"text-xs"}>{"Created by: " + selectedCategory.createdby}</div>
                <div className={"text-xs"}>{"Created at: " + getDutchDateTimeFromStamp(selectedCategory.createdat)}</div>
            </div>
            <div className={"flex flex-row w-full justify-around items-end h-36"}>
                <div className={"btn btn-error w-24"} onClick={() => {
                    DeleteCategory(selectedCategory);
                }}>Delete
                </div>
                <div className={"btn btn-ghost bg-green-600 w-24"} onClick={() => {
                    SubmitEdit();
                }}>Edit
                </div>
            </div>
        </div>
    )
}