import parse from "html-react-parser";
import React from "react";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAppContext} from "../context/appContext";

export default function ArticleView(props) {
    const {art, setEditing} = props;
    const {session, username, isAdmin} = useAppContext();

    async function SubmitDelete() {
        if(!session || !isAdmin) return;
        const {error} = await supabase.from('newsfeed')
            .delete()
            .eq('id', art.id);

        if(error){
            toast.error('Failed to delete.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Deleted news article successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
    }

    return (
        <article className="pt-6">
            <div className="xl:flex">
                <div className="w-32 shrink-0">
                    <div
                        className="text-xs font-semibold uppercase text-slate-400 dark:text-slate-500 xl:leading-8">{art.created_at}
                    </div>
                    {session && isAdmin && <button
                        className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            setEditing(true);
                        }}
                    >
                        Edit
                    </button>}
                    {session && isAdmin && <button
                        className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            SubmitDelete();
                        }}
                    >
                        Delete
                    </button>}
                </div>
                <div className="grow pb-6 border-b border-slate-200 dark:border-slate-700">
                    <header>
                        <h2 className="text-2xl font-bold mb-3">{art.title}</h2>
                        <div className="flex flex-nowrap items-center space-x-2 mb-4">
                            <div className="flex items-center">
                                <a className="block mr-2 shrink-0" href="">
                                    <img
                                        className="rounded-full border-2 border-white dark:border-slate-800 box-content"
                                        src={""} width="32" height="32" alt="User 04"/>
                                </a>
                                <a className="block text-sm font-semibold text-slate-800 dark:text-slate-100"
                                   href="">{art.posterName}</a>
                            </div>
                            <div className="text-slate-400 dark:text-slate-600">·</div>
                            <div>
                                <div
                                    className={"text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 " /*+ el.color*/}>
                                    {art.type}
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="space-y-3">
                        {parse(art.content)}
                    </div>
                </div>
            </div>
        </article>
    )
}