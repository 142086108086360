import { createContext, useContext, useEffect, useRef, useState } from "react";
import {supabase} from "../utils/supabase";
import secureLocalStorage from "react-secure-storage";
import {defaultdescription} from "../utils/routes";
import { useLocation } from 'react-router-dom';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
    const [username, setUsername] = useState("");
    const [session, setSession] = useState(null);
    const [error, setError] = useState("");
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [routeHash, setRouteHash] = useState("");
    const [isOnBottom, setIsOnBottom] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [authPanelOpen, setAuthPanelOpen] = useState(false);
    const [activeTheme, setActiveTheme] = useState(secureLocalStorage.getItem("activetheme") === null ? "Licht" : secureLocalStorage.getItem("activetheme"));
    const [showSideBar, setShowSidebar] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const SendPageOpen = (str) => {
        //window.gtag('config', 'GTM-5649XQHW', {send_page_view: false});
        window.gtag('set', 'page_path', route.pathname);
        window.gtag('set', 'page_location',route.pathname);
        window.gtag('set', 'page_title',  str);
        window.gtag('set', 'app_name',  'Tech Mania');
        window.gtag('event', 'pageOpened', { 'OpenPage': route.hash });
    }

    let route = useLocation();
    if(route.pathname === "/") {
        document.title = 'Tech Mania || Home';
    } else {
        let rt = route.pathname.split("/")[1];
        let fl = rt.charAt(0).toUpperCase();
        let fr = rt.slice(1);
        document.title = 'Tech Mania || ' + (fl + fr);
        SendPageOpen(fl + fr);
        document
            .getElementsByTagName('meta')
            .namedItem('description')
            .setAttribute('content',defaultdescription);
    }

    useEffect(() => {
        let mc = document.querySelector("#maincontainer");
        if(mc !== null) {
            mc.scrollTo({top: 0});
        }
    }, [route])

    const openPage = () => {
        if (route.pathname === "/") {
            return "landing";
        } else {
            return route.pathname.split("/")[1];
        }
    }

    const getLocation = async () => {
        try {
            const res = await fetch("https://api.db-ip.com/v2/free/self");
            const { countryCode, error } = await res.json();
            if (error) throw new Error(error);

            setCountryCode(countryCode);
            secureLocalStorage.setItem("countryCode", countryCode);
        } catch (error) {
            console.error(
                `error getting location from api.db-ip.com:`,
                error.message
            );
        }
    };

    const randomUsername = () => {
        return `@user${Date.now().toString().slice(-4)}`;
    };
    const initializeUser = async(session) => {
        setSession(session);
        // const {
        //   data: { session },
        // } = await supabase.auth.getSession();

        let username;
        if (session) {
            username = session.user.user_metadata.user_name;
        } else {
            username = secureLocalStorage.getItem("username") || randomUsername();
        }
        setUsername(username);
        try {
            const { data, error } = await supabase.rpc('is_admin');
            if(error){
                setIsAdmin(false);
            } else setIsAdmin(data);
        } catch (error){
            setIsAdmin(false);
        }
        secureLocalStorage.setItem("username", username);
        setIsInitialLoad(false);
        setLoadingInitial(false);
    };

    useEffect(() => {

        supabase.auth.getSession().then(({ data: { session } }) => {
            initializeUser(session);
            if(session && authPanelOpen) setAuthPanelOpen(false);
        });

        const storedCountryCode = secureLocalStorage.getItem("countryCode");
        if (storedCountryCode && storedCountryCode !== "undefined")
            setCountryCode(storedCountryCode);
        else getLocation();

        const {
            data: { subscription: authSubscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            console.log("onAuthStateChange", { _event, session });
            initializeUser(session);
        });

        return () => {
            authSubscription.unsubscribe();
        };
    }, []);

    function ToggleTheme(str){
        secureLocalStorage.setItem("activetheme", str);
        setActiveTheme(str);
    }

    return (
        <AppContext.Provider
            value={{
                loadingInitial,
                error,
                username,
                setUsername,
                randomUsername,
                routeHash,
                isOnBottom,
                country: countryCode,
                session,
                openPage,
                activeTheme,
                setActiveTheme,
                showSideBar,
                setShowSidebar,
                sidebarOpen,
                setSidebarOpen,
                ToggleTheme,
                authPanelOpen,
                setAuthPanelOpen,
                isAdmin
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };