export function getDutchDateTime() {
    const months = [
        "januari", "februari", "maart", "april", "mei", "juni",
        "juli", "augustus", "september", "oktober", "november", "december"
    ];

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const dateTimeString = `Tijdstip: ${hours}:${minutes}:${seconds} Datum: ${day} ${month} ${year}`;
    return dateTimeString;
}

export function getDutchDateTimeFromStamp(timestamp) {
    const months = [
        "januari", "februari", "maart", "april", "mei", "juni",
        "juli", "augustus", "september", "oktober", "november", "december"
    ];

    const currentDate = new Date(timestamp);
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const dateTimeString = `Tijdstip: ${hours}:${minutes}:${seconds} Datum: ${day} ${month} ${year}`;
    return dateTimeString;
}

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export async function ScrollToSection(target){
    await timeout(5).then(() => {
        document.querySelector("#maincontainer").scrollTo({
            top: (document.querySelector("#" + target).offsetTop) -100,
            behavior: "smooth",
        });
    });
}

export async function ScrollToSectionFast(target){
    await timeout(5).then(() => {
        document.querySelector("#maincontainer").scrollTo({
            top: (document.querySelector("#" + target).offsetTop) - 100,
            behavior: "instant",
        });
    });
}