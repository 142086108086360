import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from "./RegisterServiceWorker";
import {AppContextProvider} from "./context/appContext";
import { BrowserRouter } from 'react-router-dom';
import {NewsContextProvider} from "./context/newsContext";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <AppContextProvider>
            <NewsContextProvider>
                <App />
            </NewsContextProvider>
        </AppContextProvider>
    </BrowserRouter>
);

serviceWorkerRegistration.unregister();
