import './App.css';
import React, {useEffect, useState} from 'react';
import { Toaster } from 'react-hot-toast';
import LandingPage from "./pages/landingpage";
import Diensten from "./pages/diensten";
import ContactPage from "./pages/contact";
import AboutUs from "./pages/about";
import {Theme} from "react-daisyui";
import {TopBar} from "./components/TopBar";
import FooterV1 from "./components/FooterV1";
import AdminDashboard from "./admin/dashboard";
import WebdesignbureauAalst from "./pages/webdesignbureauaalst";
import LoadingScreen from "./utils/loadingscreen";
import {ArrowUp} from "lucide-react";
import AppOntwikkelaar from "./pages/appontwikkelaar";
import ProgrammeurAalst from "./pages/programmeuraalst";
import AppMakenVanWebsite from "./pages/appmakenvanwebsite";
import BackEndDeveloperAalst from "./pages/backenddeveloperaalst";
import MobieleAppLatenMaken from "./pages/mobieleapplatenmaken";
import NewsFeed from "./newsmodule/newsfeed";
import {LiveChat} from "./chatmodule/livechat";
import WebsiteOpMaat from "./pages/websiteopmaat";
import {Dutch} from "./utils/localization";
import AuthPanel from "./utils/authpanel";
import { useAppContext } from "./context/appContext";
import { Route, Routes } from 'react-router-dom';

function App() {
  const {session, username, setUsername, routeHash, authPanelOpen, setAuthPanelOpen, loadingInitial, openPage, activeTheme} = useAppContext();
  const [localization, setLocalization] = useState(Dutch);

  return (
      <Theme dataTheme={activeTheme}>
        {loadingInitial ? <LoadingScreen />
            :
            <div className="flex flex-col cursor-auto h-screen w-screen overflow-hidden">
              {openPage() !== "temp" && openPage() !== "admin" &&
                  <TopBar />}

              <div id={"maincontainer"} className="flex flex-col h-full w-full overflow-x-hidden overflow-y-auto">
                <Routes>
                  <Route path='/' element={<LandingPage />} />
                  <Route path='/info' element={<AboutUs />} />
                  <Route path='/nieuws' element={<NewsFeed />} />
                  <Route path='/diensten' element={<Diensten />} />
                  <Route path='/contact' element={<ContactPage />} />
                  <Route path='/webdesignbureau-aalst' element={<WebdesignbureauAalst />} />
                  <Route path='/appontwikkelaar' element={<AppOntwikkelaar />} />
                  <Route path='/programmeur-aalst' element={<ProgrammeurAalst />} />
                  <Route path='/app-maken-van-website' element={<AppMakenVanWebsite />} />
                  <Route path='/back-end-developer-aalst' element={<BackEndDeveloperAalst />} />
                  <Route path='/mobiele-app-laten-maken' element={<MobieleAppLatenMaken />} />
                  <Route path='/website-op-maat' element={<WebsiteOpMaat />} />
                  <Route path='/admin' element={<AdminDashboard />} />
                </Routes>
                {openPage() !== "temp" && openPage() !== "admin" && <FooterV1 />}
                <ScrollToTop />
                {/*<LiveChat localization={localization} openPage={openPage} session={session} setSession={setSession} />*/}
              </div>
              {!session && authPanelOpen && <AuthPanel />}
                <Toaster/>
            </div>
        }
      </Theme>
  );
}

function ScrollToTop(props) {
  const [scrollPos, setScrollPos] = useState(0);
  useEffect(() => {
    let mc = document.querySelector("#maincontainer")
    if(mc!== null) mc.addEventListener("scroll", (e) => {
      setScrollPos(e.target.scrollTop);
    })
    return(()=> {
      mc.removeEventListener("scroll", (e) => {setScrollPos(e.target.scrollTop);})
    })
  })

  function Visible(){
    if(scrollPos > 0) return true;
    else return false;
  }


  return (
      <div className={(Visible() ? "flex" : "hidden") + " fixed flex-row tooltip tooltip-left lg:tooltip-top justify-center items-center h-10 w-10 lg:h-14 lg:w-14 rounded-full bg-primary hover:bg-opacity-80 right-8 lg:right-24 bottom-20 lg:bottom-1/2 z-20"}
          onClick={() => {
            document.querySelector("#maincontainer").scrollTo({top: 0});
          }} data-tip={"Jump naar de top"}>
        <ArrowUp/>
      </div>
  )
}

export default App;
