import {useState} from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export function AccountsPage(props) {
    const {users, user, token, setOpenTab, loadingUsers} = props;
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [accountListOpen, setAccountListOpen] = useState(false);
    return (
        <div className={"flex flex-col w-full items-center justify-center h-full p-10"}>
            <div className={"flex flex-row justify-between items-center h-20 w-full py-2"}>
                <div className={"flex flex-row h-full w-auto"}>
                    <div className={"btn btn-secondary h-12 w-24"} onClick={() => setAccountListOpen(!accountListOpen)}>Select</div>
                    <p>{selectedAccount && "Selected account:" + selectedAccount.email}</p>
                </div>
                <div className={"flex flex-row h-full w-auto"}>
                    <h1>Accounts Management</h1>
                </div>
            </div>
            {accountListOpen &&
                <>
                {loadingUsers ?
                        <div className={"flex flex-col h-2/3 w-full justify-center items-center gap-1"}>
                            Loading Accounts
                        </div>
                        :
                        <div className={"flex flex-col h-2/3 w-full items-center justify-center"}>
                    <AccountsList users={users} setSelectedAccount={setSelectedAccount} selectedAccount={selectedAccount} setAccountListOpen={setAccountListOpen} />
                </div>}
                </>
            }

            <div className={"flex flex-col w-full h-full border-t border-base-300"}>
                {selectedAccount !== null &&
                    <div className={"flex flex-col justify-start items-center h-[800px] w-[500px]"}>
                        <p>{"ID: " + selectedAccount._id}</p>
                        <p>{"Accountname:" + selectedAccount.accountname}</p>
                        <p>{"Email: " + selectedAccount.email}</p>
                        <p>{"Banned:" + selectedAccount.banned.toString()}</p>
                    </div>}
            </div>
        </div>
    )
}

function AccountsList(props) {
    const {users, setSelectedAccount, selectedAccount, setAccountListOpen} = props;
    return (
        <div className={"flex flex-col w-full h-auto border-base-300 border bg-base-100"} onPointerLeave={() => { setAccountListOpen(false); }}>
            <div className={"flex flex-col w-full items-center justify-start h-full overflow-hidden p-3"}>
                <div className={"flex flex-col w-full items-center justify-start h-full overflow-y-auto overflow-x-hidden"}>
                    <div className={"flex flex-col h-auto w-full gap-1"}>
                        {users && users.map((usr) => (
                            <div
                                className={"flex flex-row w-full cursor-pointer justify-center items-center gap-2 h-14 border-base-300 hover:bg-primary hover:bg-opacity-80 text-white text-sm " + (selectedAccount !== null && selectedAccount.email === usr.email ? "bg-base-300" : "bg-base-200")}
                                key={usr._id} onClick={() => {
                                setSelectedAccount(usr);
                                setAccountListOpen(false);
                            }}>
                                {usr.admin && <AdminPanelSettingsIcon/>}
                                <p>{usr.accountname + " - " + usr.email}</p>
                                <p>{usr.created + " - " + usr.location}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}