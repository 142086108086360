import React from "react";
import {ReportClick} from "../../utils/gtag";
import {GetRoutePage} from "../../utils/routes";
import { Link } from "react-router-dom";

export default function NeemDeStapNaarDigitaleVoorsprong(props) {

    return (
        <div className={"py-8 pb-20 lg:pt-10 lg:pb-20"}>
            <div className="flex flex-row w-auto h-auto bg-base-100 justify-between items-center">
                <div className={"flex flex-col w-full lg:w-[1281px] h-auto bg-base-100"}>
                    <h3 className="text-2xl lg:text-3xl lg:leading-none font-bold">
                        <span className=""> NEEM DE STAP NAAR DIGITALE VOORSPRONG </span>
                    </h3>
                    <p className="mt-8 text-lg">
                        Bent u klaar om uw bedrijf naar een hoger niveau te tillen met een professionele website?
                        Onze <Link to={"/back-end-developer-aalst"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("back-end_developers");
                        }}>
                        back-end developers</a></Link>  staan klaar om uw visie te realiseren.
                        Als <Link to={"/"}><a
                        className="link-info link cursor-pointer text-primary font-semibold"
                        onClick={() => {
                            ReportClick("uw_IT-partner");
                        }}>
                        uw IT-partner</a></Link> nemen we de tijd om uw behoeften te begrijpen en bieden we oplossingen
                        op maat
                        om uw online aanwezigheid te versterken.
                        Neem vandaag nog contact met ons op en begin uw reis naar digitale excellentie.
                    </p>
                </div>
            </div>
        </div>
    )
}