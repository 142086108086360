import React from "react";
import ZetNuDeStapNaarOnlineSucces from "../components/webdesignbureau-aalst/zetnudestapnaaronlinesucces";
import ImageBanner3 from "../components/webdesignbureau-aalst/imagebanner3";
import InnovatieEnTechnologieVoorop from "../components/webdesignbureau-aalst/innovatieentechnologievoorop";
import MaatwerkVanUwWebdesignbureau from "../components/webdesignbureau-aalst/maatwerkvanuwwebdesignbureau";
import LinkBanner from "../components/linkbanner";
import ProgrammeurInAalst from "../components/webdesignbureau-aalst/programmeurinaalst";
import BreadCrumb from "../components/BreadCrumb";
import {GetRoutePage} from "../utils/routes";
import OnzePartners from "../components/OnzePartners";
import HeroLanding from "../components/HeroLanding";
import HeroLandingServices from "../components/HeroLandingServices";
import {HeroLandingSection1} from "../components/HeroLandingSection1";
import ImageBanner1 from "../components/ImageBanner1";
import HeroLandingSection2 from "../components/HeroLandingSection2";


export default function WebdesignbureauAalst(props) {

    return (
        <div className={""}>
            <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden">
                <>
                    <div
                        className="flex flex-row w-full h-full bg-base-100 overflow-y-auto overflow-x-hidden justify-center items-center">
                        <div className={"flex flex-col h-full w-full lg:w-2/3 xl:w-[1281px] p-8"}>
                            <div className="mt-8">
                            </div>

                            <LinkBanner/>

                            <BreadCrumb />

                            <ProgrammeurInAalst />

                            <MaatwerkVanUwWebdesignbureau />

                            <InnovatieEnTechnologieVoorop />

                            <ImageBanner3 />

                            <ZetNuDeStapNaarOnlineSucces />

                            {/*<OnzePartners/>*/}

                            {/*<div style={{minHeight: "42vh"}}></div>*/}
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}

